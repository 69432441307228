import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NoticeDocType } from 'app/notices/enums';

import { NoticeFolderQuery } from 'app/notices/store/notice-folder';
import { NoticeService } from 'app/notices/services/notice.service';
import { EMPTY, iif, Observable } from 'rxjs';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';
import { NoticeGenerationDialogComponent } from '../notice-generation-dialog/notice-generation-dialog.component';
import { switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectClientDialogComponent } from '../select-client-dialog/select-client-dialog.component';
import { NoticeDocumentsQuery } from 'app/notices/store/notice-documents';

import { DoneDialogComponent } from '@shared/components/dialogs/done-dialog/done-dialog.component';
import { fadeRowAnimation } from 'app/core/animations/fade.animation';
import { SOMETHING_GONE_WRONG } from '@constants';
import { INoticeDocument } from '../../types';

@Component({
    selector: 'avl-notice-documents',
    templateUrl: './notice-documents.component.html',
    styleUrls: ['./notice-documents.component.scss'],
    animations: [fadeRowAnimation],
})
export class NoticeDocumentsComponent implements OnInit {
    public documentTypes = NoticeDocType;
    public folderId$: Observable<string>;
    public isButtonVisible$: Observable<boolean>;
    public isLoading$: Observable<boolean>;
    public readyDocuments$: Observable<{ [p: string]: INoticeDocument }>;
    public uploadCanceled$: Observable<void>;

    constructor(
        private readonly noticeFolderQuery: NoticeFolderQuery,
        private readonly noticeDocumentsQuery: NoticeDocumentsQuery,
        private readonly noticeService: NoticeService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {
    }

    public ngOnInit(): void {
        this.folderId$ = this.noticeFolderQuery.folderId$;
        this.isLoading$ = this.noticeDocumentsQuery.selectLoading();
        this.uploadCanceled$ = this.noticeFolderQuery.onFolderInitCanceled();
        this.isButtonVisible$ = this.noticeDocumentsQuery.isLeaseAndTitleProcessed();
        this.readyDocuments$ = this.noticeDocumentsQuery.getMappedByType();
    }

    public onFileAdded(): void {
        const folderId = this.noticeFolderQuery.getValue().id;

        if (!folderId) {
            this.noticeService.initFolder()
                .pipe(tap((folderId) => this.navigateToNewFolder(folderId)))
                .subscribe();
        }
    }

    public onUploadingStarted(uploadId: string, fileName: string, type: NoticeDocType): void {
        this.noticeService.addTemporaryDocument(uploadId, type, fileName);
        this.noticeService.setIsLoading(true);
    }

    public onFileUploaded(fileId: string, uploadId: string): void {
        this.noticeService.bindUploadIdWithFileId(uploadId, fileId);
        this.noticeService.startPolling();
    }

    public openGenerateNoticeDialog(url: string): void {
        const dialogRef = this.dialog.open(NoticeGenerationDialogComponent,
            {
                data: { url },
                panelClass: 'report-dialog',
                width: '400px',
                disableClose: true,
            },
        );

        dialogRef.afterClosed().subscribe((result: string) => {
            switch (result) {
                case 'success':
                    this.openDoneDialog();
                    break;
                case 'error':
                    this.openAlertDialog(false, SOMETHING_GONE_WRONG);
                    break;
            }
        });
    }

    public openSelectClientDialog(): void {
        const dialogRef = this.dialog.open(
            SelectClientDialogComponent,
            {
                panelClass: ['report-dialog'],
                width: '470px',
            });
        dialogRef.afterClosed()
            .pipe(
                switchMap((type: string) => {
                    const folderId = this.noticeFolderQuery.getValue().id;
                    return iif(() => !!type, this.noticeService.generateNotice(folderId, type), EMPTY);
                }),
                tap((url: string) => this.openGenerateNoticeDialog(url)),
            )
            .subscribe();
    }

    public onFileRemoved(documentId: string): void {
        const folderId = this.noticeFolderQuery.getValue().id;
        this.noticeService.removeDocument(folderId, documentId).subscribe();
    }

    private navigateToNewFolder(folderId: string): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { fid: folderId },
            queryParamsHandling: 'merge',
        });
    }

    private openDoneDialog(): void {
        this.dialog.open(DoneDialogComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            disableClose: true,
            data: 'Report downloaded successfully',
        });
    }

    private openAlertDialog(withAfterClose?: boolean, errorData?: { title: string; message: string }): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            data: errorData,
        });

        if (withAfterClose) {
            // dialogRef.afterClosed().subscribe(_ => this.openSelectReportDialog());
        }
    }
}
