import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ProfileService } from '@services';
import { AvailToolKey } from '@enums';

@Injectable({
    providedIn: 'root',
})
export class SelectAvailableToolGuard implements CanActivate {
    constructor(
        private readonly profileService: ProfileService,
        private readonly router: Router,
    ) {
    }

    public canActivate(): Observable<boolean> {
        this.profileService.loadConfig();

        return this.profileService.apps$
            .pipe(
                filter((apps) => !!apps?.length),
                take(1),
                map((availableApps) => {
                    const titleApp = availableApps?.find((app) => app.key === AvailToolKey.title);
                    const firstAvailableAppKey = titleApp?.key || availableApps[0].key;

                    void this.router.navigate([firstAvailableAppKey]);

                    return false;
                }),
            );
    }
}
