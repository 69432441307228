<ng-template #uploadingTools>
    <avl-drop-area
        [loading$]="documentsLoading$"
        [landRegistryDisabled]="landRegistryButtonDisabled"
        [imanageDisabled]="imanageButtonDisabled"
        (landRegistryOpened)="onLandRegistryOpened()"
        (imanageOpened)="onImanageOpened()"
        (newFolderCreated)="navigateToNewFolder($event)"
    ></avl-drop-area>
    <avl-error-alert
        *ngIf="!documentsLoading"
        [folderId]="folderId"
        [failedDocuments]="failedDocuments$ | async"
        (documentRemoved)="onFailedDocumentRemove($event)"
    ></avl-error-alert>
    <avl-purchase-error-alert
        *ngIf="!documentsLoading"
        [folderId]="folderId"
        [failedPurchases]="failedPurchases$ | async"
        (purchaseRemoved)="onFailedPurchaseRemove($event)"
    ></avl-purchase-error-alert>
    <avl-documents
        *ngIf="succeedDocuments.length"
        avlElevate
        name="titles-table"
        [isDragDropEnabled]="isDragDropEnabled"
        [loading]="documentsLoading"
        [class.titles-documents]="succeedDocuments?.length"
        [initialSort]="sortType"
        [initialSortDirection]="orderType"
        [documents]="succeedDocuments"
    ></avl-documents>
    <button
        *ngIf="succeedDocuments?.length"
        avlElevate
        class="avl-btn avl-btn--blue avl-btn--wide titles-btn"
        name="next-button"
        [disabled]="documentsLoading || isDisabledBtn || !hasTitles.getValue()"
        (click)="onNextButtonClick()"
    >
        Next
    </button>
    <!--Imanage Iframe  -->
    <ng-container *ngIf="showImanageIframe">
        <avl-imanage-auth
            [folderId]="folderId"
            [loading$]="documentsLoading$"
        ></avl-imanage-auth>
    </ng-container>
</ng-template>

<ng-container *ngIf="isOldTheme">
    <ng-template [ngTemplateOutlet]="uploadingTools"></ng-template>
</ng-container>

<div
    *ngIf="!isOldTheme"
    class="page-container"
>
    <div class="upload-tools">
        <ng-template [ngTemplateOutlet]="uploadingTools"></ng-template>
    </div>
    <div class="other-options">
        <header class="other-options__header">
            <h3 class="other-options__title">Other Options</h3>
            <p class="other-options__subtitle">
                Don’t have your titles? Either select them on a map, carry out a PN1, or search the Land Registry
            </p>
        </header>

        <div class="option-card">
            <div class="option-card__new-label"></div>

            <div class="option-card__img-container">
                <img
                    class="option-card__img"
                    src="../../../../assets/icons/icon_map_search.svg"
                    alt="Map search"
                >
            </div>
            <button
                class="option-card__button avl-btn"
                type="button"
                (click)="showDialog(registrySearchType.map)"
            >
                Find on map
            </button>
        </div>

        <div class="option-card">
            <div class="option-card__img-container">
                <img
                    class="option-card__img"
                    src="../../../../assets/icons/icon_coat_of_arms.svg"
                    alt="Coat of arms"
                >
            </div>
            <button
                class="option-card__button avl-btn"
                type="button"
                (click)="showDialog(registrySearchType.ownership)"
            >
                Do company PN1 search
            </button>
        </div>

        <div class="option-card">
            <div class="option-card__img-container">
                <img
                    class="option-card__img"
                    src="../../../../assets/icons/icon_land_registry_colorless.svg"
                    alt="Colorless land registry"
                >
            </div>
            <button
                class="option-card__button avl-btn"
                type="button"
                (click)="showDialog(registrySearchType.titleNumber)"
            >
                Search land registry
            </button>
        </div>
    </div>
</div>
