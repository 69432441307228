import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

import { ITitleInfo } from 'app/titles/types';

import { TableRowsAmountService } from '@services';
import { ICollectionHeightOffset } from '@core/types';
import { RegistrySearchType } from '@constants';

const resultsTableHeightOffset: ICollectionHeightOffset = {
    header: 155 + 60,
    tableHeader: 36,
    tableHeaderColumns: 44,
    paginator: 0,
    footerButton: 78,
};

@Component({
    selector: 'avl-land-registry-results-table',
    templateUrl: './land-registry-results-table.component.html',
    styleUrls: ['./land-registry-results-table.component.scss'],
})
export class LandRegistryResultsTableComponent implements OnInit, OnChanges {
    public displayedColumns: string[] = [];
    public selection = new SelectionModel<ITitleInfo>(true, []);
    public titlesSource = new MatTableDataSource<ITitleInfo>([]);
    public tableRowsAmount: number;

    @Input()
    public isLoading: boolean;

    @Input()
    public isWhat3WordsColumnEnabled = false;

    @Input()
    public titles: ITitleInfo[];

    @Input()
    public selectedTitles: ITitleInfo[] = [];

    @Output()
    public selectionChanged = new EventEmitter<ITitleInfo[]>();

    private readonly allPossibleColumns =  ['reference', 'type', 'address', 'what3words', 'proprietors', 'companyNumbers', 'select'];

    constructor(
        private readonly tableRowsAmountService: TableRowsAmountService,
    ) {
    }

    public ngOnInit(): void {
        this.calculateRowsAmount();
        this.defineDisplayedColumns();
    }

    public ngOnChanges(): void {
        this.defineDisplayedColumns();
        this.titlesSource.data = this.titles;
        this.selection = new SelectionModel<ITitleInfo>(true, this.selectedTitles);
    }

    public isAllSelected(): boolean {
        const selectedNumbers = this.selection.selected.map((file) => file.titleNumber);
        const sourceNumbers = this.titlesSource.data.map((file) => file.titleNumber);

        return sourceNumbers.every((id) => selectedNumbers.includes(id));
    }

    public masterToggle(): void {
        this.isAllSelected()
            ? this.deselect(...this.titlesSource.data)
            : this.titlesSource.data.forEach((row) => !this.isSelected(row) && this.selection.select(row));
        this.selectionChanged.emit(this.selection.selected);
    }

    public toggleSelection(value: ITitleInfo): void {
        this.isSelected(value)
            ? this.deselect(value)
            : this.selection.select(value);
        this.selectionChanged.emit(this.selection.selected);
    }

    public isSelected(file: ITitleInfo): boolean {
        return !!this.selection.selected.find((selectedFile) => selectedFile.titleNumber === file.titleNumber);
    }

    private calculateRowsAmount(): void {
        this.tableRowsAmount = this.tableRowsAmountService
            .calculateRowsAmount(resultsTableHeightOffset);
    }

    private deselect(...files: ITitleInfo[]): void {
        files.forEach((file) => {
            const originFile = this.selection.selected.find((selectedFile) => selectedFile.titleNumber === file.titleNumber);
            if (originFile) {
                this.selection.deselect(originFile);
            }
        });
    }

    private defineDisplayedColumns(): void {
        if (this.isWhat3WordsColumnEnabled) {
            this.displayedColumns = [...this.allPossibleColumns];
        } else {
            this.displayedColumns = this.allPossibleColumns.filter((column) => column !== RegistrySearchType.what3Words);
        }
    }
}
