<button
    class="download-report-button"
    matRipple
    [disabled]="disableButton"
    [class.downloaded]="value.isDownloaded"
    (click)="reportDownload(value)"
>
    <img
        class="download-report-button__icon"
        [src]="'assets/images/report/' + value.icon + '.png'"
        [alt]="value.icon"
    >
    <span class="download-report-button__title display-block">{{ value.title }}</span>
    <span class="download-report-button__format">{{ value.format | documentFormat }}</span>
</button>
