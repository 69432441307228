import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import firebase from 'firebase/compat/app';

import { CacheService, HttpQueryParamsService, LoggerService } from '@services';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';

import { RoutingService } from './routing.service';

import { BehaviorSubject, Observable } from 'rxjs';
import { delayMsBeforeForceLogout, delayMsBeforeNextForceLogoutTry, HAVE_TAKEN_TOO_LONG_TOAST, SECURITY_REASON_REAUTH_TOAST } from '@constants';
import { MatDialog } from '@angular/material/dialog';
import { InfoSnackbarComponent } from '@shared/components/info-snackbar/info-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public user$: Observable<firebase.User>;
    public user?: firebase.User;

    private readonly currentTokenCookie: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly idTokenObtainedMsAgoKey = 'idWasObtainedMsAgo';
    private isSso = false;

    constructor(
        public readonly firebaseAuth: AngularFireAuth,
        private readonly http: HttpClient,
        private readonly cookieService: CookieService,
        private readonly route: ActivatedRoute,
        private readonly routingService: RoutingService,
        private readonly log: LoggerService,
        private readonly cacheService: CacheService<void>,
        private readonly matDialog: MatDialog,
        private readonly zone: NgZone,
        private readonly router: Router,
        private readonly queryParamsService: HttpQueryParamsService,
        private readonly snackBar: MatSnackBar,
    ) {
        this.user$ = firebaseAuth.authState;
        firebaseAuth.onAuthStateChanged((user) => {
            if (user) {
                this.user = user;
                this.user.getIdTokenResult().then((idTokenResult) => {
                    const provider = idTokenResult.signInProvider;
                    const idToken = idTokenResult.token;

                    this.isSso = provider.startsWith('saml.') || provider.startsWith('oidc.');
                    this.cookieService.set('auth', idToken, 0, '/', null, true, 'Lax');
                    this.currentTokenCookie.next(idToken);
                });
                this.log.info('authState changed', this.user);
            } else {
                this.user = null;
                this.log.info('no authState user');
            }
        });
        firebaseAuth.onIdTokenChanged((user) => {
            if (user) {
                this.tryToUpdateUserSession();
            }
        });
    }

    public maybeUpdateTokenCookie(newToken: string): void {
        if (newToken !== this.currentTokenCookie.getValue()) {
            this.cookieService.set('auth', newToken, 0, '/', null, true, 'Lax');
            this.currentTokenCookie.next(newToken);
        }
    }

    public async login(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.firebaseAuth.signInWithEmailAndPassword(email, password);
    }

    public async sendResetPasswordEmail(email: string): Promise<void> {
        return this.firebaseAuth.sendPasswordResetEmail(email);
    }

    public async changePassword(email: string, password: string, newPassword: string): Promise<void> {
        if (this.user && this.user.email === email) {
            const credential = firebase.auth.EmailAuthProvider.credential(email, password);

            return this.user.reauthenticateWithCredential(credential)
                .then((value) => value.user.updatePassword(newPassword));
        }

        return Promise.reject({ message: `You need to login as ${email} to be able to change the password.` });
    }

    public async confirmPasswordReset(code: string, newPassword: string): Promise<void> {
        return this.firebaseAuth.confirmPasswordReset(code, newPassword);
    }

    public async loginWithProvider(providerId: string): Promise<void> {
        let provider = null;
        if (providerId.startsWith('oidc')) {
            provider = new firebase.auth.OAuthProvider(providerId);
            provider.setCustomParameters({ returnIdpCredential: false });
        } else {
            if (!providerId.startsWith('saml.')) {
                providerId = `saml.${providerId}`;
            }

            provider = new firebase.auth.SAMLAuthProvider(providerId);
        }

        if (environment.signInPopup) {
            return new Promise((resolve, reject) => {
                this.firebaseAuth
                    .signInWithPopup(provider)
                    .then(() => this.routingService.navigateToHome(this.route.snapshot))
                    .then(() => resolve())
                    .catch(reject);
            });
        } else {
            return this.firebaseAuth.signInWithRedirect(provider);
        }
    }

    public async logout(): Promise<void> {
        this.user = null;
        this.cookieService.deleteAll();
        this.cacheService.clear();

        return this.firebaseAuth.signOut();
    }

    public async signup(code: string, passwordValue: string): Promise<any> {
        const url = `${environment.cloudFunctions}/signupUser?oobCode=${encodeURIComponent(code)}`;

        return this.http.post(url, { password: passwordValue }).toPromise();
    }

    public checkSignupCode(code: string): Observable<string> {
        const url = `${environment.cloudFunctions}/checkSignupCode?oobCode=${encodeURIComponent(code)}`;

        return this.http.post<string>(url, {});
    }

    public submitProfileInfo(profile: any): Observable<any> {
        this.log.info('submitProfileInfo', profile, `${environment.cloudFunctions}/updateUserInfo`, { displayname: profile.displayname });

        return this.http.post<string>(`${environment.cloudFunctions}/updateUserInfo`, profile);
    }

    public isProviderSso(): boolean {
        return this.isSso;
    }

    public getProviderName(): string {
        const provider = this.user?.providerData[0];

        return provider
            ? provider.providerId.replace(/saml\.|oidc\./g, '').toUpperCase()
            : 'unknown';
    }

    public showReauthenticateSnackbar(message: string = HAVE_TAKEN_TOO_LONG_TOAST): void {
        this.snackBar.openFromComponent(InfoSnackbarComponent, {
            data: message,
            duration: 12000,
        });
    }

    private tryToUpdateUserSession(): void {
        const isTokenObtainedTooLongMsAgo = this.isIdTokenObtainedTooLongAgo();
        this.saveIdTokenObtainTimestamp();

        if (isTokenObtainedTooLongMsAgo) {
            if (this.isTimeToReSignIn()) {
                this.logout();
                this.matDialog.closeAll();
                this.showReauthenticateSnackbar(SECURITY_REASON_REAUTH_TOAST);

                this.zone.run(() => {
                    const queryParams = this.queryParamsService.addRedirectToQueryParams();
                    this.router.navigate(['/login'], { queryParams });
                });
            }
        }
    }

    private isTimeToReSignIn(): boolean {
        const userMetadata = this.user?.metadata;

        if (userMetadata) {
            const lastSignInTime = new Date(userMetadata.lastSignInTime).getTime();
            const msFromLastSignIn = new Date().getTime() - lastSignInTime;

            return delayMsBeforeForceLogout < msFromLastSignIn;
        }

        return false;
    }

    private isIdTokenObtainedTooLongAgo(): boolean {
        const idTokenObtainedMsAgoStr = localStorage.getItem(this.idTokenObtainedMsAgoKey);

        if (!idTokenObtainedMsAgoStr) {
            return true;
        }

        const idTokenObtainedMsAgo = Number(idTokenObtainedMsAgoStr);
        const timeDifferenceMs = new Date().getTime() - idTokenObtainedMsAgo;

        return timeDifferenceMs > delayMsBeforeNextForceLogoutTry;
    }

    private saveIdTokenObtainTimestamp(): void {
        const timestamp = new Date().getTime();
        localStorage.setItem(this.idTokenObtainedMsAgoKey, timestamp.toString());
    }
}
