import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoticesComponent } from './notices.component';

import { SharedModule } from '@shared/shared.module';
import { NoticesRoutingModule } from './notices-routing.module';
import { NoticeAreaComponent } from './components/notice-area/notice-area.component';
import { NoticeProgressComponent } from './components/notice-progress/notice-progress.component';
import { NoticeDocumentsComponent } from './components/notice-documents/notice-documents.component';
import { NoticeGenerationDialogComponent } from './components/notice-generation-dialog/notice-generation-dialog.component';

import { NoticeFolderQuery, NoticeFolderStore } from './store/notice-folder';
import { NoticeApi } from './api/notice.api';
import { NoticeService } from './services/notice.service';
import { SelectClientDialogComponent } from './components/select-client-dialog/select-client-dialog.component';
import { NoticeDocumentsQuery, NoticeDocumentsStore } from './store/notice-documents';
import { NoticesResolver } from './resolvers/notices.resolver';
import { SidenavComponent } from './components/sidenav/sidenav.component';


@NgModule({
    declarations: [
        NoticesComponent,
        NoticeAreaComponent,
        NoticeProgressComponent,
        NoticeDocumentsComponent,
        NoticeGenerationDialogComponent,
        SelectClientDialogComponent,
        SidenavComponent,
    ],
    imports: [
        CommonModule,
        NoticesRoutingModule,
        SharedModule
    ],
    providers: [
        NoticeApi,
        NoticeService,
        NoticesResolver,
        NoticeFolderQuery,
        NoticeFolderStore,
        NoticeDocumentsQuery,
        NoticeDocumentsStore,
    ]
})
export class NoticesModule { }
