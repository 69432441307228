import { Injectable } from '@angular/core';
import { ReportApi } from '../api';
import { IReportOption } from '../types';
import { FolderQuery, ReportOptionsStore } from '../store';
import { switchMap, tap, throttleTime } from 'rxjs/operators';
import { Observable, of, timer } from 'rxjs';
import { LoggerService } from '@services';
import { HttpResponse } from '@angular/common/http';
import { ReportAction } from '@core/types';
import { orderedReportActions } from '@constants';

const API_POLLING_INTERVAL = 1000;

@Injectable()
export class ReportService {

    constructor(
        private readonly reportOptionsStore: ReportOptionsStore,
        private readonly reportApi: ReportApi,
        private readonly folderQuery: FolderQuery,
        private readonly logger: LoggerService,
    ) {
    }

    public generateReport(wait: boolean, reportId?: string): Observable<IReportOption[]> {
        const id = reportId || this.folderQuery.getValue().id;

        return this.reportApi.generateReport(id, wait)
            .pipe(
                tap((response) => this.setReportOptions(response)),
            );
    }

    public reportStatus(): Observable<HttpResponse<IReportOption[]>> {
        return timer(API_POLLING_INTERVAL * 3, API_POLLING_INTERVAL * 3)
            .pipe(
                throttleTime(API_POLLING_INTERVAL * 2),
                switchMap(() => this.getReportStatus()),
            );
    }

    public downloadReportFile(key: string, format: string): Observable<HttpResponse<any>> {
        const id = this.folderQuery.getFolderId();

        return this.reportApi.loadReport(id, key, format);
    }

    public resetReport(): void {
        this.reportOptionsStore.reset();
    }

    public markAsDownloaded(id: number): void {
        this.reportOptionsStore.upsert(id, { isDownloaded: true });
    }

    private getReportStatus(): Observable<HttpResponse<IReportOption[]> | null> {
        const folderId = this.folderQuery.getValue().id;

        return folderId
            ? this.reportApi.getReportStatus(folderId)
                .pipe(
                    tap((response) => this.setReportOptions(response.body)),
                )
            : of(null);
    }

    private setReportOptions(actions: IReportOption[]): void {
        this.logger.info('setReportOptions', actions);

        const reportActions = this.mapReportActions(actions);
        this.reportOptionsStore.set(reportActions);
    }

    private mapReportActions(actions: IReportOption[]): ReportAction[] {
        const actionsOrder = orderedReportActions;
        const sortedList: ReportAction[] = [];
        const actionsOrderIds: number[] = actionsOrder.map((action) => action.displayOrder);
        const maxDisplayOrder = Math.max(...actionsOrderIds);

        actions.forEach((action, index) => {
            const actionOrder = actionsOrder.find((actionOrder) => {
                const isKeysEquals = actionOrder.key === action.key;
                const isFormatsEquals = actionOrder.format === action.formats[0];

                return isKeysEquals && isFormatsEquals;
            });

            if (!actionOrder && !action.bespoke) {
                return;
            }

            const newDisplayOrder = actionOrder?.displayOrder || maxDisplayOrder + index;
            sortedList.push({
                id: newDisplayOrder,
                displayOrder: newDisplayOrder,
                key: action.key,
                title: action.name,
                icon: action.icon,
                format: action.formats[0],
                isDownloaded: false,
            });
        });

        return sortedList;
    }
}
