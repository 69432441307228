import { MapSearchType } from '@constants';
import { LngLat } from 'maplibre-gl';
import { AddressSearchResult, MapSearchResult } from '../../../types';

export type Suggestion = {
    value: string;
    placeId?: string;
    location?: LngLat;
};

export type SuggestionsGroup = {
    typeName: string;
    type: MapSearchType;
    isLoading: boolean;
    suggestions: Suggestion[];
};

export function mapTitleNumbersSearchResultToSuggestions(searchResult: MapSearchResult[]): Suggestion[] {
    return searchResult.map((item) => ({
        value: item.searchQuery,
        location: new LngLat(item.longitude, item.latitude),
    }));
}

export function mapAddressesSearchResultToSuggestions(addressSearchResult: AddressSearchResult[]): Suggestion[] {
    return addressSearchResult.map((item) => ({
        value: item.description,
        placeId: item.placeId,
    }));
}
