import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LandRegistryApi } from '../api';
import { ITileNumberForPurchase, ITitleInfo } from '../types';
import { concatMap, delay, expand, filter, finalize, map, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, Subject } from 'rxjs';
import { SearchResultsStore } from '../store';
import { PurchasedTitleDetails } from '../types/purchased-title-details.type';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { InfoSnackbarComponent } from '@shared/components/info-snackbar/info-snackbar.component';
import { MatSnackBarConfig } from '@angular/material/snack-bar/snack-bar-config';

@Injectable()
export class LandRegistrySearchService {

    private readonly tooLongSearchSnackBarAppearingDelayMs = 4000;
    private snackBarRef?: MatSnackBarRef<InfoSnackbarComponent>;
    private tooLongSearchSnackBarSetTimeoutRef?: NodeJS.Timeout;

    constructor(
        private readonly landRegistryApi: LandRegistryApi,
        private readonly searchResultStore: SearchResultsStore,
        private readonly snackBar: MatSnackBar,
    ) {
    }

    public searchTitleRegisters(folder: string, kind: string, query: string, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        this.startLoading();
        statusUrl.next(null);

        return this.landRegistryApi.search(folder, kind, query)
            .pipe(
                map((response: HttpResponse<null>) => response.headers.get('Content-Location')),
                switchMap((url) => this.getSearchStatus(url, statusUrl)),
                finalize(() => this.stopLoading()),
            );
    }

    public downloadSearchResults(url: string): Observable<HttpResponse<any>> {
        return this.landRegistryApi.downloadSearchResults(url);
    }

    public refreshTitles(folder: string, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        this.startLoading();

        return this.landRegistryApi.refresh(folder)
            .pipe(
                map((response: HttpResponse<null>) => response.headers.get('Content-Location')),
                switchMap((url) => this.getSearchStatus(url, statusUrl)),
                finalize(() => this.stopLoading()),
            );
    }

    public resetState(): void {
        this.searchResultStore.reset();
    }

    public removeTitleRegister(titleNumber: string): void {
        this.searchResultStore.remove(titleNumber);
    }

    public purchaseTitles(folder: string, titles: ITitleInfo[]): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        const titleNumbers: ITileNumberForPurchase[] = titles.map((item) => {
            return { kind: 'title-register', reference: item.titleNumber };
        });

        return this.landRegistryApi.purchaseTitles(folder, titleNumbers).pipe(
            map((response: HttpResponse<null>) => response.headers.get('Content-Location')),
            concatMap((url) => this.getPurchaseStatus(url)),
        );
    }

    private getSearchStatus(url: string, statusUrl: Subject<string>): Observable<ITitleInfo[]> {
        const searchStatus$ = this.landRegistryApi.getSearchStatus(url);

        return searchStatus$.pipe(
            expand((response) => {
                return response.status === 202
                    ? searchStatus$.pipe(delay(2 * 1000))
                    : EMPTY;
            }),
            filter((response) => {
                return response.status !== 202 || !!response.body?.length;
            }),
            map((response) => {
                this.searchResultStore.set(response.body);

                if (statusUrl) {
                    statusUrl.next(url.replace('/search/', '/search-to-excel/'));
                }

                this.searchResultStore.setLoading(false);

                return response.body;
            }),
        );
    }

    private getPurchaseStatus(url: string): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        const purchaseStatus$ = this.landRegistryApi.getPurchaseStatus(url);

        return purchaseStatus$.pipe(
            expand((response) => {
                return response.status === 202
                    ? purchaseStatus$.pipe(delay(3000))
                    : EMPTY;
            }),
        );
    }

    private startLoading(): void {
        this.searchResultStore.setLoading(true);
        this.tooLongSearchSnackBarSetTimeoutRef = setTimeout(
            () => {
                this.showTooLongSearchSnackBar();
                this.tooLongSearchSnackBarSetTimeoutRef = null;
            },
            this.tooLongSearchSnackBarAppearingDelayMs,
        );
    }

    private stopLoading(): void {
        this.searchResultStore.setLoading(false);
        this.dismissTooLongSearchSnackBar();
    }

    private showTooLongSearchSnackBar(): void {
        if (!this.snackBarRef?.instance) {
            const options: MatSnackBarConfig = {
                data: 'This is taking a little long, please be patient',
            };

            this.snackBarRef = this.snackBar.openFromComponent(InfoSnackbarComponent, options);
            this.snackBarRef.afterDismissed().subscribe(() => this.snackBarRef = null);
        }
    }

    private dismissTooLongSearchSnackBar(): void {
        this.snackBarRef?.dismiss();

        if (this.tooLongSearchSnackBarSetTimeoutRef) {
            clearTimeout(this.tooLongSearchSnackBarSetTimeoutRef);
            this.tooLongSearchSnackBarSetTimeoutRef = null;
        }
    }
}
