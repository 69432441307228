import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { MapComponent } from './map.component';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgxMapLibreGLModule,
    ],
    exports: [
        MapComponent,
        MapComponent,
    ],
    declarations: [
        MapComponent,
        MapComponent,
    ],
})
export class MapModule {
}
