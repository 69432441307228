import { Routes } from '@angular/router';
import { AuthGuard } from '@auth/guards';
import { LeasesComponent } from './leases.component';
import { LeasesUploadComponent } from './components/leases-upload/leases-upload.component';
import { LeasesResolver } from './resolvers/leases.resolver';
import { PreviousFoldersComponent } from './components/previous-folders/previous-folders.component';
import { ToolAccessGuard } from '../core/guards/tool-access.guard';
import { AvailToolKey } from '@enums';

export const leasesRoutes: Routes = [
    {
        path: 'lease',
        component: LeasesComponent,
        canActivate: [ToolAccessGuard],
        data: { tool: AvailToolKey.lease, title: 'Avail Lease' },
        children: [
            {
                path: '',
                redirectTo: 'upload',
                pathMatch: 'full',
            },

            {
                path: 'upload',
                component: LeasesUploadComponent,
                canActivate: [AuthGuard],
                resolve: { status: LeasesResolver },
            },
            {
                path: 'previous-projects',
                component: PreviousFoldersComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'leases',
        redirectTo: 'lease',
    },
];
