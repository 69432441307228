import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

type IAppIcon = {
    name: string;
    src: string;
}

@Component({
    selector: 'avl-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public loading = false;

    private readonly appIcons: IAppIcon[] = [
        { name: 'add', src: '../assets/icons/icon_add.svg' },
        { name: 'bell', src: '../assets/icons/icon_notification_bell.svg' },
        { name: 'support', src: '../assets/icons/icon_support.svg' },
        { name: 'profile', src: '../assets/icons/icon_profile.svg' },
        { name: 'download', src: '../assets/icons/icon_download.svg' },
        { name: 'download-white', src: '../assets/icons/icon_download_white.svg' },
        { name: 'pdf', src: '../assets/icons/icon_pdf.svg' },
        { name: 'close', src: '../assets/icons/icon_close.svg' },
        { name: 'close-white', src: '../assets/icons/icon_close_white.svg' },
        { name: 'close-red', src: '../assets/icons/icon_close_red.svg' },
        { name: 'drop', src: '../assets/icons/icon_drop.svg' },
        { name: 'success', src: '../assets/icons/icon_success.svg' },
        { name: 'alert', src: '../assets/icons/icon_alert.svg' },
        { name: 'arrow-progress', src: '../assets/icons/icon_arrow_progress.svg' },
        { name: 'arrow-up', src: '../assets/icons/icon_arrow_up.svg' },
        { name: 'nav-right', src: '../assets/icons/icon_nav_right.svg' },
        { name: 'nav-left', src: '../assets/icons/icon_nav_left.svg' },
        { name: 'nav-go', src: '../assets/icons/icon_nav_go.svg' },
        { name: 'scottish-title', src: '../assets/icons/icon_scotlis_beta.svg' },
        { name: 'title-register', src: '../assets/icons/icon_land_registry.svg' },
        { name: 'title-register-analysis', src: '../assets/icons/icon_land_registry_analysis.svg' },
        { name: 'title-plan', src: '../assets/icons/icon_title_plan.svg' },
        { name: 'epc', src: '../assets/icons/icon_epc.svg' },
        { name: 'notice-loading-arrow', src: '../assets/icons/icon_notice_loading_arrow.svg' },
        { name: 'notice-error', src: '../assets/icons/icon_notice_error.svg' },
        { name: 'hamburger-menu', src: '../assets/icons/icon_hamburger_menu.svg' },
        { name: 'archive', src: '../assets/icons/icon_archive.svg' },
        { name: 'file-chart', src: '../assets/icons/icon_file_chart.svg' },
        { name: 'plus-box', src: '../assets/icons/icon_plus_box.svg' },
        { name: 'plus-box-without-paddings', src: '../assets/icons/icon_plus_box_without_paddings.svg' },
        { name: 'icon-search', src: '../assets/icons/icon_search.svg' },
        { name: 'icon-search-white', src: '../assets/icons/icon_search_white.svg' },
        { name: 'add-all-checkbox', src: '../assets/icons/icon_add_all_check.svg' },
        { name: 'alert-circle', src: '../assets/icons/icon_alert_circle.svg' },
        { name: 'sync', src: '../assets/icons/icon_sync.svg' },
        { name: 'file-chart-grey', src: '../assets/icons/file_chart_grey.svg' },
        { name: 'user', src: '../assets/icons/icon_user.svg' },
        { name: 'archive-grey', src: '../assets/icons/archive_grey.svg' },
        { name: 'file-chart-rounded', src: '../assets/icons/file_chart_rounded.svg' },
        { name: 'land-registry-logo-grey', src: '../assets/icons/land_registry_logo_gray.svg' },
        { name: 'icon-check', src: '../assets/icons/icon_check.svg' },
        { name: 'cloud-download', src: '../assets/icons/icon_cloud_download.svg' },
        { name: 'companies-house', src: '../assets/icons/icon_companies_house.svg' },
        { name: 'ordnance-survey', src: '../assets/icons/icon_ordnance_survey.svg' },
        { name: 'planning', src: '../assets/icons/icon_planning_home.svg' },
        { name: 'logo', src: '../assets/icons/icon_avail_logo.svg' },
        { name: 'error', src: '../assets/icons/icon_error.svg' },
        { name: 'eye', src: '../assets/icons/icon_eye.svg' },
        { name: 'eye-slash', src: '../assets/icons/icon_eye_slash.svg' },
        { name: 'envelope', src: '../assets/icons/icon_envelope.svg' },
        { name: 'file-attachment', src: '../assets/icons/icon_file_attachment.svg' },
        { name: 'triangle', src: '../assets/icons/triangle.svg' },
        { name: 'explanation-triangle', src: '../assets/icons/icon_explanation_triangle.svg' },
        { name: 'reorder', src: '../assets/icons/icon_reorder.svg' },
    ];

    constructor(
        private readonly matIconRegistry: MatIconRegistry,
        private readonly domSanitizer: DomSanitizer,
        private readonly router: Router,
        private readonly titleService: Title,
    ) {
    }

    public ngOnInit(): void {
        this.registerIcons(this.appIcons);
        this.checkRouterEvents();
        this.updateTitle();
    }

    private registerIcons(icons: IAppIcon[]): void {
        icons.forEach((icon) => {
            this.matIconRegistry.addSvgIcon(
                icon.name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(icon.src),
            );
        });
    }

    private checkRouterEvents(): void {
        this.router.events.subscribe((event: Event) => {
            const navigationNegativeStates = [
                NavigationEnd,
                NavigationCancel,
                NavigationError,
            ];

            if (event instanceof NavigationStart) {
                this.loading = true;
            } else if (navigationNegativeStates.some((state) => event instanceof state)) {
                this.loading = false;
            }
        });
    }

    private updateTitle(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.router.routerState.root.firstChild?.snapshot.data['title'] || 'Avail'),
            )
            .subscribe((pageTitle: string) => this.titleService.setTitle(pageTitle));
    }
}
