import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { Actions } from '@datorama/akita-ng-effects';
import { tap } from 'rxjs/operators';
import { MapSearchQuery, MapSearchStore, resetTitleData, restoreStateBeforeFocusing } from 'app/titles/store';
import { addTitleToBasket, LandRegistryQuery, removeTitleFromBasket } from 'app/titles/store/land-registry';
import { ITitleInfo } from '../../../../types';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'avl-map-land-registry-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['detail.component.scss'],
})
export class MapLandRegistryDetailComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public title: ITitleInfo;

    @Output()
    public backButtonClicked = new EventEmitter();

    public isInBasket = false;
    public isBackButtonVisible$: Observable<boolean>;

    private readonly sub = new Subscription();

    constructor(
        private readonly mapSearchStore: MapSearchStore,
        private readonly ref: ChangeDetectorRef,
        private readonly mapSearchQuery: MapSearchQuery,
        private readonly landRegistryQuery: LandRegistryQuery,
        private readonly actions: Actions,
    ) {
    }

    public ngOnInit(): void {
        this.sub.add(
            this.landRegistryQuery.isTitleAddedToBasket$(this.title.titleNumber)
                .pipe(
                    tap((isInBasket) => {
                        this.isInBasket = isInBasket;
                        setTimeout(() => this.ref.detectChanges());
                    }),
                )
                .subscribe(),
        );
        this.isBackButtonVisible$ = this.mapSearchQuery.isSelectedMultipleFeatures();
    }

    public ngOnChanges(): void {
        this.ref.detectChanges();
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    public basketToggle(): void {
        if (this.isInBasket) {
            this.actions.dispatch(removeTitleFromBasket({ titleNumber: this.title.titleNumber }));
        } else {
            this.actions.dispatch(addTitleToBasket({ title: this.title }));
        }
    }

    public emitToBack(): void {
        this.mapSearchStore.update({ previousShowTitleId: this.title.titleNumber });
        this.actions.dispatch(restoreStateBeforeFocusing());
        this.actions.dispatch(resetTitleData());
        this.backButtonClicked.emit();
    }
}
