import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IGeneratedReport, INotificationCounter } from '../types';

@Injectable()
export class BookmarksApi {

    constructor(private readonly http: HttpClient) {
    }

    public getReports(params: HttpParams): Observable<HttpResponse<IGeneratedReport[]>> {
        return this.http.get<IGeneratedReport[]>('api/titles/bookmarks', { params, observe: 'response' });
    }

    public getActivePurchases(): Observable<INotificationCounter> {
        return this.http.get<INotificationCounter>('/api/hmlr/purchased/active');
    }

    public getActiveBookmarks(): Observable<INotificationCounter> {
        return this.http.get<INotificationCounter>('/api/titles/bookmarks/active');
    }
}
