import { Routes } from '@angular/router';

import { TitleRegistersComponent } from '../components/title-registers/title-registers.component';
import { TitlesResolve } from '../resolvers/titles.resolve';

import { AuthGuard } from '@auth/guards';
import { TitlesUploadComponent } from '../components/titles-upload/titles-upload.component';
import { PurchasedPageComponent } from '../components/purchased-page/purchased-page.component';
import { ReportsPageComponent } from '../components/reports-page/reports-page.component';
import { TitlesSchedulesTableComponent } from '../components/titles-schedules/titles-schedules.component';
import { SchedulesResolve } from '../resolvers/schedules.resolve';
import { ToolAccessGuard } from '../../core/guards/tool-access.guard';
import { AvailToolKey } from '@enums';
import { ThemesResolve } from '../resolvers/themes.resolve';

export const titlesRoutes: Routes = [
    {
        path: 'title',
        component: TitleRegistersComponent,
        canActivate: [ToolAccessGuard],
        data: { tool: AvailToolKey.title, title: 'Avail Title' },
        children: [
            {
                path: '',
                redirectTo: 'upload',
                pathMatch: 'full',
            },
            {
                path: 'upload',
                component: TitlesUploadComponent,
                resolve: { folder: TitlesResolve, isOldTheme: ThemesResolve },
                canActivate: [AuthGuard],
            },
            {
                path: 'upload/:imanage',
                component: TitlesUploadComponent,
                resolve: { folder: TitlesResolve, isOldTheme: ThemesResolve },
                canActivate: [AuthGuard],
            },
            {
                path: 'schedules',
                component: TitlesSchedulesTableComponent,
                resolve: { folder: TitlesResolve, schedules: SchedulesResolve },
                canActivate: [AuthGuard],
            },
            {
                path: 'purchased',
                component: PurchasedPageComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'reports',
                component: ReportsPageComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'titles',
        redirectTo: 'title',
    },
];
