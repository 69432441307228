import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { NoticeFolderStore } from '../store/notice-folder';
import { NoticeApi } from '../api/notice.api';
import { NoticeDocumentsQuery, NoticeDocumentsStore } from '../store/notice-documents';
import { createEmptyClientNoticeDocument, INoticeDocument } from '../types';
import { Observable, Subscription, timer } from 'rxjs';
import { FolderDetails, IResponseStatus } from '@core/types';
import { NoticeDocType } from '../enums';
import { EventType, FolderDetailsData, FolderDetailsDialogComponent } from '@shared/components/dialogs/folder-details-dialog/folder-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';

@Injectable()
export class NoticeService {
    private readonly statusPollingIntervalMs = 3000;
    private readonly reportGenerationPollingIntervalMs = 3000;
    private statusPoll$: Observable<IResponseStatus<INoticeDocument>> = null;
    private statusPollSubscription: Subscription = null;

    constructor(
        private readonly noticeDocumentsQuery: NoticeDocumentsQuery,
        private readonly noticeFolderStore: NoticeFolderStore,
        private readonly noticeDocumentsStore: NoticeDocumentsStore,
        private readonly api: NoticeApi,
        private readonly dialog: MatDialog,
    ) {
    }

    public initFolder(): Observable<string> {
        return this.openFolderDetailsDialog()
            .pipe(
                filter((result) => {
                    const isDetailsConfirmed = !!result && result.event === EventType.confirm;

                    if (!isDetailsConfirmed) {
                        this.noticeFolderStore.onFolderInitCanceled$.next();
                    }

                    return isDetailsConfirmed;
                }),
                map((folderDetails) => folderDetails.data),
                switchMap((folderDetails) =>
                    this.api.createFolder()
                        .pipe(
                            tap((folderId) => this.api.updateFolderDetails(folderId, folderDetails).subscribe()),
                            tap((id) => this.noticeFolderStore.update({ id, ...folderDetails })),
                        ),
                ),
            );
    }

    public openFolderDetailsDialog(folderDetails?: FolderDetails): Observable<FolderDetailsData> {
        const dialogRef = this.dialog.open(FolderDetailsDialogComponent, {
            data: folderDetails,
            panelClass: ['folder-details-dialog', 'notice-folder-details-dialog'],
            autoFocus: false,
        });

        return dialogRef.afterClosed();
    }

    public updateFolderDetails(folderId: string, details: FolderDetails): void {
        this.api.updateFolderDetails(folderId, details)
            .subscribe(() => this.noticeFolderStore.update({ ...details }));
    }

    public bindUploadIdWithFileId(uploadId: string, documentId: string): void {
        this.noticeDocumentsStore.update((doc) => doc.id === uploadId, { id: documentId });
    }

    public startPolling(): void {
        if (!this.statusPoll$) {
            const folderId = this.noticeFolderStore.getValue().id;

            this.statusPoll$ = timer(this.statusPollingIntervalMs, this.statusPollingIntervalMs)
                .pipe(
                    switchMap(() => this.api.getFolderStatus(folderId)),
                    tap((response) => {
                        const documents = response.documents;

                        this.syncDocumentsWithState(documents);
                        this.checkIsAllDocumentsUploaded();
                    }),
                );
            this.statusPollSubscription = this.statusPoll$.subscribe();
        }
    }

    public stopPolling(): void {
        this.statusPoll$ = null;
        this.statusPollSubscription?.unsubscribe();
    }

    public loadFolderStatusAndDetails(folderId: string): Observable<INoticeDocument[]> {
        return this.api.getFolderDetails(folderId)
            .pipe(
                switchMap((folderDetails) =>
                    this.api.getFolderStatus(folderId)
                        .pipe(
                            map((response) => {
                                const documents = response.documents;

                                this.noticeFolderStore.update({ id: response.id, ...folderDetails });
                                this.noticeDocumentsStore.upsertMany(documents);
                                this.checkIsAllDocumentsUploaded();

                                return documents;
                            }),
                        ),
                ),
            );
    }

    public generateNotice(folderId: string, noticeType: string): Observable<string> {
        return this.api.generateNotice(folderId, noticeType)
            .pipe(
                map((response) => response.headers.get('Content-Location')),
            );
    }

    public getGenerationStatus(url: string): Observable<HttpResponse<void>> {
        return timer(this.reportGenerationPollingIntervalMs, this.reportGenerationPollingIntervalMs)
            .pipe(
                switchMap(() => this.api.getGenerationStatus(url)),
            );
    }

    public removeDocument(folderId: string, documentId: string): Observable<void> {
        return this.api.removeDocument(folderId, documentId)
            .pipe(
                tap(() => this.noticeDocumentsStore.remove(documentId)),
            );
    }

    public checkIsAllDocumentsUploaded(): void {
        const isUploadingFinished = this.noticeDocumentsQuery.isEveryProcessed();

        this.setIsLoading(!isUploadingFinished);

        if (isUploadingFinished) {
            this.stopPolling();
        }
    }

    public setIsLoading(isLoading: boolean): void {
        this.noticeDocumentsStore.setLoading(isLoading);
    }

    public addTemporaryDocument(id: string, type: NoticeDocType, fileName: string): void {
        const tempDocument = this.createTemporaryDocument(id, type, fileName);
        this.noticeDocumentsStore.add(tempDocument);
    }

    public openAlertDialog(errorData?: { title: string; message: string }): void {
        this.dialog.open(AlertDialogComponent, {
            panelClass: 'report-dialog',
            width: '400px',
            data: errorData,
        });
    }

    private createTemporaryDocument(id: string, type: NoticeDocType, fileName: string): INoticeDocument {
        const emptyDocument = createEmptyClientNoticeDocument();
        emptyDocument.id = id;
        emptyDocument.clientType = type;
        emptyDocument.fileName = fileName;

        return emptyDocument;
    }

    private syncDocumentsWithState(documents: INoticeDocument[]): void {
        documents.forEach((uploadedDocument) => {
            this.noticeDocumentsStore.update(
                (doc) => doc.id === uploadedDocument.id,
                { ...uploadedDocument },
            );
        });
    }
}
