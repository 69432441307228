import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FolderQuery, MapSearchQuery } from 'app/titles/store';
import { finalize, switchMap } from 'rxjs/operators';
import { addTitleToBasket, LandRegistryQuery, removeTitleFromBasket } from '../../../../../store/land-registry';
import { Actions } from '@datorama/akita-ng-effects';
import { MapSearchApi } from '../../../../../api';
import { of, Subject, Subscription } from 'rxjs';

@Component({
    selector: 'avl-map-land-registry-list-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
})
export class MapLandRegistryListItemComponent implements OnInit, OnDestroy {
  @Input()
    public titleNumber: string;

  @Input()
  public titleTenure: string;

  @Input()
  public isHighlighted = false;

  @Output()
  public infoButtonClick = new EventEmitter();

  public isBasketLoading = new Subject<boolean>();
  public isInBasket = false;
  public isTitleNumberHighlighted = false;

  private readonly sub = new Subscription();

  constructor(
    private readonly mapSearchApi: MapSearchApi,
    private readonly folderQuery: FolderQuery,
    private readonly landRegistryQuery: LandRegistryQuery,
    private readonly actions: Actions,
    private readonly mapSearchQuery: MapSearchQuery,
    private readonly ref: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
      this.sub.add(
          this.landRegistryQuery.isTitleAddedToBasket$(this.titleNumber)
              .subscribe((isInBasket) => this.isInBasket = isInBasket)
      );
      this.sub.add(
          this.mapSearchQuery.isTitleNumberPermanentlyHighlighted(this.titleNumber)
              .subscribe((isHighlighted) => {
                  this.isTitleNumberHighlighted = isHighlighted;
                  this.ref.detectChanges();
              })
      );
  }

  public ngOnDestroy(): void {
      this.sub.unsubscribe();
  }

  public basketToggle(): void {
      const titleNumber = this.titleNumber;
      const folderId = this.folderQuery.getFolderId();

      if (this.isInBasket) {
          this.actions.dispatch(removeTitleFromBasket({ titleNumber }));
      } else {
          this.isBasketLoading.next(true);
          this.mapSearchApi.getTitleDetails(folderId, titleNumber)
              .pipe(
                  switchMap((titlesSearchResult) => of(titlesSearchResult[0])),
                  finalize(() => this.isBasketLoading.next(false)),
              )
              .subscribe((title) => this.actions.dispatch(addTitleToBasket({ title })));
      }
  }
}
