import { Injectable } from '@angular/core';
import { MapSearchStore } from './map-search.store';
import { UrlParamsService } from '@services';

@Injectable()
export class MapSearchService {

    constructor(
        private readonly store: MapSearchStore,
        private readonly urlParams: UrlParamsService,
    ) {
    }

    public resetTitleData(): void {
        this.store.update({
            details: null,
            focusedFeatures: null,
            stateBeforeFocusing: null,
            isSelectionUnderPinBlocked: false,
            temporaryHighlightedTitleNumber: null,
        });
        this.urlParams.removeParams(['titleNumber']);
    }
}
