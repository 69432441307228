<div class="notice-documents">
    <avl-notice-area
        class="notice-documents__item"
        [document]="(readyDocuments$ | async)?.[documentTypes.landlord]"
        [type]="documentTypes.landlord"
        [folderId]="folderId$ | async"
        [title]="'Drop Landlord’s Title Register here'"
        [cancelUploadEvent]="uploadCanceled$"
        (fileUploaded)="onFileUploaded($event.fileId, $event.uploadId)"
        (fileAdded)="onFileAdded()"
        (fileRemoved)="onFileRemoved($event)"
        (uploadingStarted)="onUploadingStarted($event.uploadId, $event.fileName, documentTypes.landlord)"
    ></avl-notice-area>
    <avl-notice-area
        class="notice-documents__item"
        [document]="(readyDocuments$ | async)?.[documentTypes.tenant]"
        [type]="documentTypes.tenant"
        [folderId]="folderId$ | async"
        [title]="'Drop Tenant’s Title Register here'"
        [cancelUploadEvent]="uploadCanceled$"
        (fileUploaded)="onFileUploaded($event.fileId, $event.uploadId)"
        (fileAdded)="onFileAdded()"
        (fileRemoved)="onFileRemoved($event)"
        (uploadingStarted)="onUploadingStarted($event.uploadId, $event.fileName, documentTypes.tenant)"
    ></avl-notice-area>
    <avl-notice-area
        class="notice-documents__item"
        [document]="(readyDocuments$ | async)?.[documentTypes.lease]"
        [type]="documentTypes.lease"
        [folderId]="folderId$ | async"
        [title]="'Drop Lease here'"
        [cancelUploadEvent]="uploadCanceled$"
        (fileUploaded)="onFileUploaded($event.fileId, $event.uploadId)"
        (fileAdded)="onFileAdded()"
        (fileRemoved)="onFileRemoved($event)"
        (uploadingStarted)="onUploadingStarted($event.uploadId, $event.fileName, documentTypes.lease)"
    ></avl-notice-area>
    <button
        *ngIf="isButtonVisible$ | async"
        class="avl-btn avl-btn--notice-yellow avl-btn--wide notice-documents__btn"
        [disabled]="isLoading$ | async"
        [@fadeRowAnimation]=""
        (click)="openSelectClientDialog()"
    >
        Generate notices
    </button>
</div>
