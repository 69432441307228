<button
    type="button"
    class="dialog__close"
    mat-dialog-close
>
    <mat-icon
        class="close-icon"
        svgIcon="close"
    ></mat-icon>
</button>
<form [formGroup]="form">
    <h4 class="select-client__title">Select your client</h4>
    <div class="select-client__options">
        <div class="radio-option">
            <input
                id="landlord"
                class="radio-option-input"
                type="radio"
                value="landlord"
                name="type"
                formControlName="type"
            >
            <label
                class="radio-option-label"
                for="landlord"
            >
                <img
                    class="radio-option-icon"
                    src="assets/images/landlord-select.png"
                    alt="landlord select"
                >
                <span class="radio-option-name">Landlord</span>
            </label>
        </div>
        <div class="radio-option">
            <input
                id="tenant"
                class="radio-option-input"
                type="radio"
                value="tenant"
                name="type"
                formControlName="type"
            >
            <label
                class="radio-option-label"
                for="tenant"
            >
                <img
                    class="radio-option-icon"
                    src="assets/images/tenant-select.png"
                    alt="tenant select"
                >
                <span class="radio-option-name">Tenant</span>
            </label>
        </div>
    </div>
    <div class="warning-text">
        Avail Notice uses advanced generative AI to analyse your documents and create your notices.
        This technology can make mistakes, so you should review the notices to ensure you're happy with their
        accuracy and completeness. Click
        <a
            class="warning-text__link"
            href="https://intercom.help/avail-10110cd30914/en/articles/9662430-how-accurate-is-avail-notice"
            target="_blank"
        >here</a>
        for more info.
    </div>
    <button
        class="avl-btn avl-btn--notice-yellow avl-btn--wide dialog__bottom-btn"
        type="button"
        [disabled]="!form.value.type"
        (click)="downloadReport()"
    >
        <span class="avl-btn__text">Select</span>
    </button>
</form>
