import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/guards';
import { NoticesComponent } from './notices.component';
import { NoticesResolver } from './resolvers/notices.resolver';
import { routingNotice } from '@constants';
import { ToolAccessGuard } from '../core/guards/tool-access.guard';
import { AvailToolKey } from '@enums';


const routes: Routes = [
    {
        path: routingNotice,
        canActivate: [ToolAccessGuard],
        data: { tool: AvailToolKey.notice, title: 'Avail Notice' },
        children: [
            {
                path: '',
                component: NoticesComponent,
                canActivate: [AuthGuard],
                resolve: {
                    noticeDocuments: NoticesResolver,
                },
            },
        ],
    },
    {
        path: 'notices',
        redirectTo: routingNotice,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class NoticesRoutingModule {
}
