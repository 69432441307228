import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NO_SUBSCRIPTION_TO_APP_CONFIG } from '@constants';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AvailToolKey } from '@enums';
import { AlertOkDialogComponent } from '@shared/components/dialogs/alert-ok-dialog/alert-ok-dialog.component';
import { ProfileService } from '@services';

@Injectable({
    providedIn: 'root',
})
export class ToolAccessGuard implements CanActivate {
    constructor(
        private readonly profileService: ProfileService,
        private readonly dialog: MatDialog,
        private readonly router: Router,
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        this.profileService.loadConfig();

        const targetTool: AvailToolKey = route.data.tool;

        return this.profileService.apps$
            .pipe(
                filter((apps) => !!apps?.length),
                take(1),
                map((apps) => apps.map((el) => el.key)),
                map((apps) => apps.includes(targetTool)),
                switchMap((hasAccess) => {
                    if (hasAccess) {
                        return of(true);
                    }

                    const dialogRef = this.dialog.open(AlertOkDialogComponent, NO_SUBSCRIPTION_TO_APP_CONFIG);

                    return dialogRef.afterClosed()
                        .pipe(
                            map(() => false),
                            tap(() => this.router.navigate([''])),
                        );
                }),
            );
    }
}
