import { ISearchType } from '@core/types';

export const routingTitles = 'titles';
export const routingNotice = 'notice';
export const routingSchedules = 'schedules';

export const mapSearchMaxZoom = 22;
export const mapSearchMinZoom = 5;
export const mapSearchMaxZoomFeatureVisibility = 17;
export const mapSearchZoomAfterSearch = mapSearchMaxZoomFeatureVisibility;

export enum RegistrySearchType {
    titleNumber = 'title-number',
    companyNumber = 'company-number',
    ownership = 'ownership',
    address = 'address',
    map = 'map-search',
    what3Words = 'what3words',
}

export const landRegistrySearchTypes: ISearchType[] = [
    { value: RegistrySearchType.titleNumber, label: 'Title Number', isBeta: false },
    { value: RegistrySearchType.ownership, label: 'Company Name', isBeta: false },
    { value: RegistrySearchType.address, label: 'Address', isBeta: false },
    { value: RegistrySearchType.companyNumber, label: 'Company No.', isBeta: false },
    { value: RegistrySearchType.map, label: 'Map Search', isBeta: true },
    { value: RegistrySearchType.what3Words, label: 'what3words', isBeta: true },
];

export enum MapSearchType {
    titleNumber = 'title_number',
    address = 'address',
}

export const landRegistrySearchHints = new Map<RegistrySearchType, string>([
    [RegistrySearchType.titleNumber, 'Type or copy/paste title numbers to search HM Land Registry'],
    [RegistrySearchType.address, 'Type in the address, preferably the postcode'],
    [RegistrySearchType.ownership, 'Type in the name of a company to find its land titles'],
    [RegistrySearchType.companyNumber, 'Type or copy/paste company numbers to find their titles'],
    [RegistrySearchType.map, 'Enter any single title number, individual address, or click around the map'],
    [RegistrySearchType.what3Words, 'Type or copy/paste what3words queries'],
]);

export const mapSearchTypeName = new Map<MapSearchType, string>([
    [MapSearchType.titleNumber, 'Title Numbers'],
    [MapSearchType.address, 'Addresses'],
]);
