<button
    mat-dialog-close
    class="dialog__close"
    type="button"
    [disabled]="isCloseDisabled"
>
    <mat-icon
        class="close-icon"
        svgIcon="close"
    ></mat-icon>
</button>
<h4 mat-dialog-title>Download Report</h4>
<mat-dialog-content>
    <p class="download-report__subtitle">Click on any button to download</p>
    <ul class="download-report__list">
        <li
            *ngFor="let button of (downloadButtons$ | async)"
            class="download-report__list-item"
        >
            <avl-download-report-button
                [value]="button"
                [disableButton]="isButtonsDisabled"
                (reportDownloaded)="onReportDownloaded($event)"
            ></avl-download-report-button>
        </li>
    </ul>
</mat-dialog-content>
