import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@datorama/akita-ng-effects';
import { LandRegistryStore, PurchasingStatus } from './land-registry.store';
import { tap } from 'rxjs/operators';
import {
    addTitleToBasket,
    changeSearchType,
    doPurchaseTitles,
    removeTitleFromBasket,
    titlesPurchaseFailed,
    titlesPurchaseSuccessful,
    updateBasketList,
} from './land-registry.actions';
import { LandRegistryService } from './land-registry.service';
import { UrlParamsService } from '@services';

@Injectable()
export class LandRegistryEffects {
    @Effect()
    public changeSearchType$ = this.actions$.pipe(
            ofType(changeSearchType),
            tap(({ searchType }) => this.urlParams.addParams({ searchType })),
        );

    @Effect()
    public addTitleToBasket$ = this.actions$.pipe(
            ofType(addTitleToBasket),
            tap((action) => {
                const targetTitle = action.title;
                const allTitles = this.landRegistryStore.getValue().basketTitles;
                const isTitleInStore = allTitles.some((title) => title.titleNumber === targetTitle.titleNumber);

                if (!isTitleInStore) {
                    this.landRegistryStore.update({ basketTitles: [...allTitles, targetTitle] });
                }
            }),
        );

    @Effect()
    public removeTitleFromBasket$ = this.actions$.pipe(
            ofType(removeTitleFromBasket),
            tap((action) => {
                const targetTitleNumber = action.titleNumber;
                const allTitles = this.landRegistryStore.getValue().basketTitles;
                const titlesForPurchase = allTitles.filter((title) => title.titleNumber !== targetTitleNumber);

                this.landRegistryStore.update({ basketTitles: titlesForPurchase });
            }),
        );

    @Effect()
    public updateBasketList$ = this.actions$.pipe(
            ofType(updateBasketList),
            tap((action) => this.landRegistryStore.update({ basketTitles: action.titles })),
        );

    @Effect()
    public doPurchaseTitles$ = this.actions$.pipe(
            ofType(doPurchaseTitles),
            tap((action) => {
                const folderId = action.folderId;
                this.landRegistryService.doPurchaseTitles(folderId);
            }),
        );

    @Effect()
    public titlePurchaseSuccessful$ = this.actions$.pipe(
            ofType(titlesPurchaseSuccessful),
            tap((action) => {
                const message = action.message;
                const status = PurchasingStatus.success;

                this.landRegistryStore.emitPurchaseFinished({ status, message });
            }),
        );

    @Effect()
    public titlePurchaseFailed$ = this.actions$.pipe(
            ofType(titlesPurchaseFailed),
            tap((action) => {
                const message = action.message;
                const status = PurchasingStatus.failure;

                this.landRegistryStore.emitPurchaseFinished({ status, message });
            }),
        );

    constructor(
        private readonly actions$: Actions,
        private readonly landRegistryStore: LandRegistryStore,
        private readonly landRegistryService: LandRegistryService,
        private readonly urlParams: UrlParamsService,
    ) {
    }
}
