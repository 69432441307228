import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';

import { SharedModule } from '@shared/shared.module';

import { LoginComponent } from './components/login/login.component';
import { ResetPasswordRequestComponent } from './components/reset-password-request/reset-password-request.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignupComponent } from './components/signup/signup.component';

import { AuthLayoutComponent } from '@auth/components/auth-layout/auth-layout.component';

import { AuthService } from '@auth/services';
import { CookieService } from 'ngx-cookie-service';

import { ResetCodeResolver, SignupCodeResolver } from '@auth/resolvers';
import { ErrorFormComponent } from './components/error-form/error-form.component';
import { SucceedFormComponent } from './components/succeed-form/succeed-form.component';
import { ImanageLoginComponent } from './components/imanage-login/imanage-login.component';

import { ImanageLayoutComponent } from '@auth/components/imanage-layout/imanage-layout.component';
import { ImanageRedirectComponent } from './components/imanage-redirect/imanage-redirect.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NoAppsComponent } from '@auth/components/no-apps/no-apps.component';


@NgModule({
    declarations: [
        LoginComponent,
        ResetPasswordRequestComponent,
        ChangePasswordComponent,
        ResetPasswordComponent,
        SignupComponent,
        AuthLayoutComponent,
        ErrorFormComponent,
        SucceedFormComponent,
        ImanageLoginComponent,
        ImanageLayoutComponent,
        ImanageRedirectComponent,
        NoAppsComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        AuthService,
        CookieService,
        ResetCodeResolver,
        SignupCodeResolver,
    ],
})
export class AuthModule {
}
