<avl-upload-drop-area
    [options]="getUploaderConfig()"
    [isLoading]="documentsLoading$ | async"
    (beforeUpload)="onBeforeUpload($event)"
    (fileUploadingStarted)="onFileUploadStarted($event)"
    (fileUploadingFailed)="onFileUploadingFailed($event)"
    (fileUploadingCanceled)="onFileUploadingCanceled($event)"
    (fileUploadingSucceeded)="onFileUploadingSucceeded($event)"
    (unsupportedFileAdded)="onUnsupportedFileAdded($event)"
>
    <span title>Drop Leases here</span>
    <span description>or click to upload them</span>
    <span upload-label>upload leases</span>
</avl-upload-drop-area>
<avl-documents-table
    *ngIf="documents.length"
    class="leases-upload__documents-table"
    [documents]="documents"
    [isLoading]="documentsLoading$ | async"
    (documentRemoved)="onDocumentRemoved($event)"
    (documentLoadingCanceled)="onDocumentCanceled($event)"
    (documentsSorted)="onDocumentsSorted($event)"
></avl-documents-table>
<avl-button
    *ngIf="documents.length"
    class="leases-upload__next-button"
    [disabled]="documentsLoading$ | async"
    [badgeValue]="getUploadedDocumentsCount()"
    (clicked)="onGenerateReport()"
>
    Generate Lease Report
</avl-button>
