import { Injectable } from '@angular/core';
import { catchError, delay, expand, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { EMPTY, NEVER, Observable, throwError } from 'rxjs';
import {
    AddressSearchResult,
    AddressSearchResultToMap,
    convertToMapSearchType,
    ITitleInfo,
    mapAddressSearchResult,
    MapSearchProxyKey,
    MapSearchResult,
    ShortTitleInfo,
} from '../types';
import { CacheService, HttpClientUtilsService } from '@services';
import { LandRegistrySearchSource } from '../enums/land-registry-search-source.enum';
import { LngLat } from 'maplibre-gl';
import { LandRegistryApi } from './land-registry.api';

@Injectable()
export class MapSearchApi {

    constructor(
        private readonly landRegistryApi: LandRegistryApi,
        private readonly titleDetailsCacheService: CacheService<ITitleInfo[]>,
        private readonly http: HttpClient,
        private readonly httpUtils: HttpClientUtilsService,
        private readonly titleNumberSearchResultCacheService: CacheService<MapSearchResult[]>,
        private readonly addressSearchResultCacheService: CacheService<AddressSearchResult[]>,
        private readonly selectionQueryCacheService: CacheService<ShortTitleInfo[]>,
    ) {
    }


    public titleNumberSuggestions(query: string, { isCacheEnabled = false }: { isCacheEnabled?: boolean } = {}): Observable<MapSearchResult[]> {
        const url = '/api/mapping/query-search';
        const params = new HttpParams()
            .set('query', query);
        const cacheKey = `${url}${params.toString()}`;
        const observable = this.http.get<{
            latitude: number;
            longitude: number;
            text: string;
            type: string;
        }[]>(url, { params })
            .pipe(
                map((items) =>
                    items.map((item) => ({
                        latitude: item.latitude,
                        longitude: item.longitude,
                        searchQuery: item.text,
                        type: convertToMapSearchType(item.type),
                    })),
                ),
            );

        return isCacheEnabled
            ? this.titleNumberSearchResultCacheService.findOrSet(cacheKey, observable.pipe(shareReplay({ bufferSize: 1, refCount: true })))
            : observable;
    }

    public addressSuggestions(query: string, { isCacheEnabled = false }: { isCacheEnabled?: boolean } = {}): Observable<AddressSearchResult[]> {
        const url = '/api/mapping/address/autocomplete';
        const params = new HttpParams()
            .set('query', query);
        const cacheKey = `${url}${params.toString()}`;
        const observable = new Observable<AddressSearchResult[]>((subscriber) => {
            this.http.get<AddressSearchResultToMap[]>(url, { params })
                .pipe(
                    map((items) =>
                        items.map((item) => mapAddressSearchResult(item)),
                    ),
                    catchError(() => {
                        subscriber.next([]);

                        return EMPTY;
                    }),
                )
                .subscribe((value) => {
                    subscriber.next(value);
                    subscriber.complete();
                });
        });

        return isCacheEnabled
            ? this.addressSearchResultCacheService.findOrSet(cacheKey, observable.pipe(shareReplay({ bufferSize: 1, refCount: true })))
            : observable;
    }

    public getTitleDetails(folderId: string, titleNumber: string, { isCacheEnabled = false }: { isCacheEnabled?: boolean } = {}): Observable<ITitleInfo[]> {
        const cacheKey = `fid/${folderId}/title-number/${titleNumber}`;
        const observable = this.landRegistryApi.search(folderId, 'title-number', titleNumber, LandRegistrySearchSource.mapSearch)
            .pipe(
                map((response: HttpResponse<null>) => response.headers.get('Content-Location')),
                switchMap((url) => this.getSearchResult(url)),
                catchError((error) => {
                    return throwError(error);
                }),
            );

        return isCacheEnabled
            ? this.titleDetailsCacheService.findOrSet(cacheKey, observable.pipe(shareReplay(1)))
            : observable;
    }

    public getMappingProxyAccessKey(folderId: string): Observable<MapSearchProxyKey> {
        const url = `/api/mapping/${folderId}/map-search-key`;

        return this.http.get<{
            // eslint-disable-next-line @typescript-eslint/naming-convention
            expires_at: Date;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            folder_id: string;
            key: string;
        }>(url)
            .pipe(
                map((response) => ({
                    expiresAt: response.expires_at,
                    folderId: response.folder_id,
                    key: response.key,
                })),
            );
    }

    public selection(options: {
        isCacheEnabled: boolean;
        titleNumber?: string;
        point?: LngLat;
    } = { isCacheEnabled: false, titleNumber: null, point: null }): Observable<ShortTitleInfo[]> {
        const retryDelay = 200;
        const retryMaxAttempts = 5;

        const url = '/api/mapping/selection';
        const params = options.point
            ? new HttpParams()
                .set('lon', options.point.lng)
                .set('lat', options.point.lat)
            : new HttpParams()
                .set('title', options.titleNumber);
        const cacheKey = `${url}${params.toString()}`;

        const observable = this.http.get<{
            // eslint-disable-next-line @typescript-eslint/naming-convention
            title_number: string;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            poly_id: string[];
            tenure: string;
        }[]>(url, { params })
            .pipe(
                map((values) =>
                    values.map((value) => ({
                        titleNumber: value.title_number,
                        polyId: value.poly_id,
                        tenure: value.tenure,
                    })),
                ),
                this.httpUtils.repeatIfServerError(retryMaxAttempts, retryDelay),
            );
        const observableForSaving = observable.pipe(
            catchError(() => {
                this.selectionQueryCacheService.removeValue(cacheKey);

                return NEVER;
            }),
            shareReplay(1),
        );

        return options.isCacheEnabled
            ? this.selectionQueryCacheService.findOrSet(cacheKey, observableForSaving)
            : observable;
    }

    private getSearchResult(url: string): Observable<ITitleInfo[]> {
        const searchStatus$ = this.landRegistryApi.getSearchStatus(url);

        return searchStatus$.pipe(
            expand((response) =>
                // TODO: need investigate this delay and find solution to remove the delay
                response.status === 202
                    ? searchStatus$.pipe(delay(500))
                    : EMPTY,
            ),
            filter((response) => response.status !== 202 || !!response.body.length),
            map((response) => response.body),
        );
    }
}
