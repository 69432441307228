import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { fadeRowAnimation } from 'app/core/animations/fade.animation';

import { IFailedDocument } from '@core/types';
import { ID } from '@datorama/akita';


@Component({
    selector: 'avl-multiple-error-alert',
    templateUrl: './multiple-error-alert.component.html',
    styleUrls: ['./multiple-error-alert.component.scss'],
    animations: [fadeRowAnimation],
})
export class MultipleErrorAlertComponent implements OnChanges {

    public isAlertOpened = false;

    @Input()
    public titleNumbers: string[];

    @Output()
    public documentRemoved = new EventEmitter<string>();

    public ngOnChanges(): void {
        this.isAlertOpened = !!this.titleNumbers.length;
    }

    public removeDocument(id: string): void {
        this.documentRemoved.emit(id);
    }

    public trackByDocuments(index: string, document: IFailedDocument): ID {
        return document.id;
    }

    public removeOneDocument(): void {
        this.documentRemoved.emit(this.titleNumbers[0]);
    }
}
