import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { persistState, PersistStateSelectFn } from '@datorama/akita';
import { MapSearchState } from './app/titles/store';
import { debounceTime } from 'rxjs/operators';

if (environment.sentryData.isEnabled) {
    let integrationsList = [];
    if (environment.sentryData.isTracingEnabled) {
        integrationsList = [
            new BrowserTracing({
                tracingOrigins: ['localhost', /^\//],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ];
    }
    Sentry.init({
        dsn: environment.sentryData.dsn,
        debug: environment.dev,
        environment: environment.dev ? 'dev' : 'production',
        integrations: integrationsList,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.sentryData.tracesSampleRate,
    });
}

if (environment.production) {
    enableProdMode();
}

const mapSearch: PersistStateSelectFn<MapSearchState> = (state) => {
    return {
        zoom: state.zoom,
        center: state.center,
        isFreeholdsOn: state.isFreeholdsOn,
        isLeaseholdsOn: state.isLeaseholdsOn,
    };
};
mapSearch.storeName = 'map-search';

const mapSearchPersistState = persistState({
    key: 'map-position',
    include: ['map-search'],
    select: [mapSearch],
    preStorageUpdateOperator: () => debounceTime(2000),
});
const noticePersistState = persistState({
    key: 'notice',
    include: ['notice-documents'],
});

const providers = [
    { provide: 'persistStorage', useValue: mapSearchPersistState },
    { provide: 'persistStorage', useValue: noticePersistState },
];

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
// eslint-disable-next-line no-console
    .catch((err) => console.error(err));
