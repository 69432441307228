<table class="loading-table">
    <tr
        *ngFor="let row of arrayRows()"
        class="loading-table__row"
    >
        <td
            *ngFor="let column of columns"
            [ngClass]="'mat-column-' + column"
        >
            <span>
                <div class="loading-table__loader"></div>
            </span>
        </td>
    </tr>
</table>
