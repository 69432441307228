<div class="wrapper">
    <img
        class="head-bg"
        src="/assets/images/avail-notice-sidenav-bg.png"
        alt="avail notice sidenav background"
    >
    <div class="buttons">
        <button
            class="button"
            (click)="onCreateNewProject()"
        >
            <mat-icon
                class="button-icon"
                svgIcon="plus-box-without-paddings"
            ></mat-icon>
            <span>New Project</span>
        </button>
    </div>

    <avl-other-tools
        class="apps"
        [excludeTool]="tools.notice"
    ></avl-other-tools>
</div>
