import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ITitleInfo } from '../../types';
import { Observable, Subject } from 'rxjs';

export enum PurchasingStatus {
    success = 'success',
    failure = 'error'
}

export type PurchaseFinishEvent = {
    status: PurchasingStatus;
    message: string;
}

export type LandRegistryState = {
    basketTitles: ITitleInfo[];
}

function createInitialState(): LandRegistryState {
    return {
        basketTitles: [],
    };
}

@Injectable()
@StoreConfig({ name: 'land-registry', resettable: true })
export class LandRegistryStore extends Store<LandRegistryState> {
    private readonly purchaseFinished = new Subject<PurchaseFinishEvent>();

    constructor() {
        super(createInitialState());
        this.setLoading(false);
    }

    public purchaseFinished$(): Observable<PurchaseFinishEvent> {
        return this.purchaseFinished.asObservable();
    }

    public emitPurchaseFinished(purchaseFinishEvent: PurchaseFinishEvent): void {
        this.purchaseFinished.next(purchaseFinishEvent);
    }
}
