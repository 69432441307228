import { Injectable } from '@angular/core';
import { LandRegistryStore } from './land-registry.store';
import { catchError, finalize, switchMap, takeLast, tap } from 'rxjs/operators';
import { AlertOkDialogComponent } from '@shared/components/dialogs/alert-ok-dialog/alert-ok-dialog.component';
import { throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsService, LandRegistrySearchService } from '../../services';
import { Actions } from '@datorama/akita-ng-effects';
import { titlesPurchaseFailed, titlesPurchaseSuccessful } from './land-registry.actions';

@Injectable()
export class LandRegistryService {

    constructor(
        private readonly actions: Actions,
        private readonly landRegistryStore: LandRegistryStore,
        private readonly dialog: MatDialog,
        private readonly documentsService: DocumentsService,
        private readonly searchService: LandRegistrySearchService,
    ) {
    }

    public resetState(): void {
        this.landRegistryStore.reset();
    }

    public doPurchaseTitles(folderId: string): void {
        const titles = this.landRegistryStore.getValue().basketTitles;

        this.documentsService.clearFailedPurchases();
        this.landRegistryStore.setLoading(true);

        this.searchService.purchaseTitles(folderId, titles)
            .pipe(
                takeLast(1),
                tap(() => this.actions.dispatch(titlesPurchaseSuccessful({ message: 'Title registers purchased successfully' }))),
                catchError((error) => {
                    const status = error && error.status;
                    let message = error.error || '';
                    let withClosing = true;

                    switch (status) {
                        case 503:
                            message = message || 'You can only obtain official copies from HM Land Registry during their working hours. Please try again between 6:30 and 23:00';
                            withClosing = false;
                            break;
                        case 400:
                            message = 'Something\'s gone wrong. Please clear your temporary internet files and try again.';
                            break;
                        case 401:
                        case 403:
                            message = 'Your account doesn\'t seem to be authorised to do this. Please try again or contact our support live chat.';
                            break;
                        case 402:
                            message = 'You\'ve run out of credit. Please contact your head of innovation or LegalTech for more information.';
                            withClosing = false;
                            break;
                        case 409:
                            message = 'Another transaction of this project is in progress. '
                            + 'Please wait until the other transaction is complete to avoid purchasing the same items twice.';
                            withClosing = false;
                            break;
                        default:
                            message = 'Something\'s gone wrong. Please try again, or contact our support live chat.';
                            break;
                    }

                    if (withClosing) {
                        this.actions.dispatch(titlesPurchaseFailed({ message }));
                    } else {
                        this.dialog.open(AlertOkDialogComponent, {
                            panelClass: 'report-dialog',
                            width: '400px',
                            data: {
                                title: 'Oops!',
                                message,
                                button: 'OK',
                            },
                        });
                    }

                    return throwError(error);
                }),
                tap((res) => this.documentsService.updateFailedPurchases(res.body, this.extractTransactionId(res.url))),
                switchMap(() => this.documentsService.refreshDocuments()),
                finalize(() => this.landRegistryStore.setLoading(false)),
            )
            .subscribe();
    }

    private extractTransactionId(url: string): string {
        const tokens = url.split('/');

        if (tokens.length > 0) {
            return tokens[tokens.length - 1];
        }

        return '';
    }
}
