<div
    *ngIf="isAlertOpened"
    class="error-alert__multiple"
    @fadeRowAnimation
>
    <mat-expansion-panel
        class="error-alert__multiple-panel"
        [expanded]="titleNumbers.length > 1"
        [hideToggle]="titleNumbers.length === 1"
        [disabled]="titleNumbers.length === 1"
    >
        <mat-expansion-panel-header
            [collapsedHeight]="'50px'"
            [expandedHeight]="'50px'"
        >
            <mat-icon
                class="error-alert__icon"
                svgIcon="alert"
            ></mat-icon>
            <p
                class="error-alert__text"
                [ngPlural]="titleNumbers.length"
            >
                <ng-template ngPluralCase="=1">
                    We could not locate Title Number {{ titleNumbers[0] }} on
                    <b> HM Land Registry</b>
                </ng-template>
                <ng-template ngPluralCase="other">
                    Oops! A few title numbers that you’ve entered could not be located on
                    <b> HM Land Registry</b>. Please double check them.
                </ng-template>
            </p>
            <div
                *ngIf="titleNumbers.length === 1"
                class="error-alert__remove-icon"
                (click)="removeOneDocument()"
            >
                <mat-icon
                    class="remove-icon"
                    svgIcon="close-white"
                ></mat-icon>
            </div>
        </mat-expansion-panel-header>
        <ng-container *ngIf="titleNumbers.length > 1">
            <mat-panel-description *ngFor="let titleNumber of titleNumbers; trackBy: trackByDocuments">
                <div class="error-alert__icon">
                    <mat-icon
                        class="alert-icon"
                        svgIcon="alert"
                    ></mat-icon>
                </div>
                <p class="error-alert__text">
                    We could not locate Title Number {{ titleNumber }} on
                    <b> HM Land Registry</b>
                </p>
                <div
                    (click)="removeDocument(titleNumber)"
                    class="error-alert__remove-icon"
                >
                    <mat-icon
                        class="remove-icon"
                        svgIcon="close-white"
                    ></mat-icon>
                </div>
            </mat-panel-description>
        </ng-container>
    </mat-expansion-panel>
</div>
