import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

import { fadeRowAnimation } from '../../../../core/animations/fade.animation';
import {
    clearState,
    FolderQuery,
    loadTitleFeatureLocationAndSearchByTitleNumber,
    searchByAddress,
    searchByTitleNumber,
    SearchResultsQuery,
    updateMapFilter,
    updateMapZoom,
} from '../../../store';
import { FolderService, LandRegistrySearchService } from '../../../services';
import { ITitleInfo } from '../../../types';
import { takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FileService, LoggerService } from '@services';
import { ConfirmDialogComponent } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    MapSearchType,
    PURCHASE_A_LOT_OF_TITLES,
    RegistrySearchType,
    SOMETHING_GONE_WRONG,
    TOO_MANY_FILES_TO_PURCHASE,
    TOO_MANY_TITLE_NUMBERS,
} from '@constants';
import { AlertOkDialogComponent } from '@shared/components/dialogs/alert-ok-dialog/alert-ok-dialog.component';
import { titlesQuantityThresholdForPrevention, titlesQuantityThresholdForWarning } from '../../../constants';
import { ProjectDetailsDialogComponent } from '../project-details-dialog/project-details-dialog.component';
import { OnboardingManageService } from '../../../../onboarding/services';
import { ReportGenerationHandlerService } from '../../../services';
import { Actions } from '@datorama/akita-ng-effects';
import { changeSearchType, doPurchaseTitles, LandRegistryQuery, LandRegistryService, updateBasketList } from '../../../store/land-registry';
import { LandRegistryDialogOptions } from '../../../types';
import { SearchResult } from '../../land-registry-search/types/search-result.type';

@Component({
    selector: 'avl-land-registry-dialog',
    templateUrl: './land-registry-dialog.component.html',
    styleUrls: ['./land-registry-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [fadeRowAnimation],
})
export class LandRegistryDialogComponent implements OnInit, OnDestroy {
    public searchType: string;
    public resultTitles$: Observable<ITitleInfo[]>;
    public errorTitles$: Observable<string[]>;
    public titlesFromBasket$: Observable<ITitleInfo[]>;
    public titlesCount$: Observable<number>;
    public totalPrice$: Observable<number>;
    public isResultsListEmpty = true;
    public purchaseLoading$: Observable<boolean>;
    public loading$: Observable<boolean>;
    public searchResultsUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public initialSearchType = RegistrySearchType.titleNumber;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private readonly dialogRef: MatDialogRef<LandRegistryDialogComponent>,
        private readonly dialog: MatDialog,
        private readonly folderQuery: FolderQuery,
        private readonly folderService: FolderService,
        private readonly resultsQuery: SearchResultsQuery,
        private readonly landRegistryService: LandRegistryService,
        private readonly searchService: LandRegistrySearchService,
        private readonly fileService: FileService,
        private readonly snackBar: MatSnackBar,
        private readonly log: LoggerService,
        private readonly onboarding: OnboardingManageService,
        private readonly handlerService: ReportGenerationHandlerService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data: LandRegistryDialogOptions,
        private readonly actions: Actions,
        private readonly landRegistryQuery: LandRegistryQuery,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
    ) {
    }

    public ngOnInit(): void {
        this.initSearchType();
        this.initMapSearch();

        this.resultTitles$ = this.resultsQuery.getTitleInfos();
        this.errorTitles$ = this.resultsQuery.getTitlesWithError();
        this.loading$ = this.resultsQuery.getLoading();
        this.titlesFromBasket$ = this.landRegistryQuery.getTitlesFromBasket$();
        this.titlesCount$ = this.landRegistryQuery.countTitlesInBasket$();
        this.totalPrice$ = this.landRegistryQuery.totalPriceOfTitlesBasket$().pipe(
            tap(() => setTimeout(() => this.ref.detectChanges())),
        );
        this.purchaseLoading$ = this.landRegistryQuery.selectLoading();
        this.landRegistryQuery.purchasingFinished$()
            .subscribe((event) => this.dialogRef.close({ result: event.status, message: event.message }));

        if (this.data.withRefresh) {
            this.refreshTitles();
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.searchService.resetState();
        this.landRegistryService.resetState();
        this.actions.dispatch(clearState());
    }

    public isMapSearch(kind: string): boolean {
        return kind === RegistrySearchType.map;
    }

    public isWhat3Words(kind: string): boolean {
        return kind === RegistrySearchType.what3Words;
    }

    public onSearchChanged({ kind, query, location, locationType, placeId }: SearchResult): void {
        if (this.isMapSearch(kind)) {
            return locationType === MapSearchType.titleNumber
                ? this.actions.dispatch(searchByTitleNumber({ location, titleNumber: query }))
                : this.actions.dispatch(searchByAddress({ placeId }));
        }

        const folderId = this.folderQuery.getValue().id;
        this.searchService.searchTitleRegisters(folderId, kind, query, this.searchResultsUrl$)
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe({
                next: () => this.isResultsListEmpty = false,
                error: (error) => {
                    this.searchService.resetState();
                    this.isResultsListEmpty = false;

                    if (error.status === 429) {
                        this.dialog.open(AlertOkDialogComponent, {
                            width: '400px',
                            data: TOO_MANY_TITLE_NUMBERS,
                        });
                    }
                },
            });
    }

    public onSearchTypeChanged(type: RegistrySearchType): void {
        this.searchType = type;
        this.actions.dispatch(changeSearchType({ searchType: type }));
    }

    public downloadSearchResults(): void {
        if (this.searchResultsUrl$.getValue()) {
            this.log.info('downloadSearchResults');
            this.searchService.downloadSearchResults(this.searchResultsUrl$.getValue()).subscribe(
                (response) => {
                    this.fileService.download(response);
                },
                () => {
                    this.snackBar.open('Sorry, unable to download the search results', 'Dismiss', { duration: 5000, verticalPosition: 'top' });
                },
            );
        }
    }

    public onSelectedTitles(selectedTitles: ITitleInfo[]): void {
        this.actions.dispatch(updateBasketList({ titles: selectedTitles }));
    }

    public onTitleErrorRemove(titleNumber: string): void {
        this.searchService.removeTitleRegister(titleNumber);
    }

    public onTitlesPurchased(): void {
        this.onboarding.destroyOnboarding();

        const folderId = this.folderQuery.getFolderId();
        const titlesForPurchase = this.landRegistryQuery.getTitlesFromBasket();
        const titlesAmount = titlesForPurchase.length;
        const isSizeRestrictionMet = titlesAmount <= titlesQuantityThresholdForPrevention;
        const isSizeRestrictionWarning = titlesAmount > titlesQuantityThresholdForWarning;

        if (!isSizeRestrictionMet) {
            this.dialog.open(AlertOkDialogComponent, TOO_MANY_FILES_TO_PURCHASE).afterClosed();
            return;
        }

        if (isSizeRestrictionWarning) {
            const dialogData = Object.assign({}, PURCHASE_A_LOT_OF_TITLES);
            const totalPrice = this.landRegistryQuery.totalPriceOfTitlesBasket();

            dialogData.subtitle = `You will incur a cost of £${totalPrice} on your client/matter file.`;

            const dialogRefHm = this.dialog.open(ConfirmDialogComponent, {
                panelClass: 'confirm-dialog', data: dialogData,
            });

            dialogRefHm.afterClosed().subscribe((isConfirm) => {
                if (!isConfirm) {
                    return;
                }
                this.folderService.projectAlreadyCreated(folderId)
                    .subscribe({
                        next: (isCreated) => {
                            isCreated ? this.closeHmLandRegistryDialog() : this.showSetProjectDetailsAndPurchase();
                        },
                    });
            });
        } else {
            this.folderService.projectAlreadyCreated(folderId)
                .subscribe({
                    next: (isCreated) => {
                        isCreated ? this.closeHmLandRegistryDialog() : this.showSetProjectDetailsAndPurchase();
                    },
                });
        }
    }

    private initSearchType(): void {
        const type = this.data.searchType;
        if (type) {
            this.initialSearchType = type;
        }

        this.onSearchTypeChanged(this.initialSearchType);

        if (type === RegistrySearchType.map) {
            this.initMapSearch();
        }
    }

    private initMapSearch(): void {
        const location = this.data.location;
        const titleNumber = this.data.titleNumber || '';
        const zoom = this.data.zoom;
        const filters = this.data.filters;

        let markerPosition;
        const markerLat = +this.route.snapshot.queryParams.markerLat;
        const markerLng = +this.route.snapshot.queryParams.markerLng;

        if (markerLat && markerLng) {
            markerPosition = {
                lat: markerLat,
                lng: markerLng,
            };
        }

        if (location) {
            this.actions.dispatch(loadTitleFeatureLocationAndSearchByTitleNumber({ titleNumber, markerPosition }));
        }

        if (zoom) {
            this.actions.dispatch(updateMapZoom({ zoom }));
        }

        if (filters) {
            this.actions.dispatch(updateMapFilter(filters));
        }
    }

    private refreshTitles(): void {
        const folderId = this.folderQuery.getFolderId();
        this.searchService.refreshTitles(folderId, this.searchResultsUrl$)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: () => this.isResultsListEmpty = false,
                error: () => this.isResultsListEmpty = false,
            });
    }

    private closeHmLandRegistryDialog(): void {
        // check if it is land registry(1) or imanage(2)
        this.doPurchaseTitles();
    }

    private showSetProjectDetailsAndPurchase(): void {
        const dialogRef = this.dialog.open<ProjectDetailsDialogComponent, null, 'success' | 'error'>(
            ProjectDetailsDialogComponent,
            {
                panelClass: 'project-details-dialog',
                disableClose: this.onboarding.isActive,
                autoFocus: false,
            });

        dialogRef.afterClosed()
            .subscribe((result) => {
                if (result === 'success') {
                    // check if it is land registry(1) or imanage(2)
                    this.doPurchaseTitles();
                } else if (result === 'error') {
                    this.handlerService.openAlertDialog(false, SOMETHING_GONE_WRONG);
                }
            });
    }

    private doPurchaseTitles(): void {
        const folderId = this.folderQuery.getFolderId();
        this.actions.dispatch(doPurchaseTitles({ folderId }));
    }
}
