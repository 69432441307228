import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ReportOptionsState, ReportOptionsStore } from './report-options.store';
import { Observable } from 'rxjs';
import { ReportAction } from '@core/types';
import { map } from 'rxjs/operators';

@Injectable()
export class ReportOptionsQuery extends QueryEntity<ReportOptionsState> {
    constructor(protected store: ReportOptionsStore) {
        super(store);
    }

    public getSorted$(): Observable<ReportAction[]> {
        return this.selectAll()
            .pipe(
                map((actions) => actions.sort((a, b) => a.displayOrder - b.displayOrder)),
            );
    }
}
