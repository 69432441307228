import { Component } from '@angular/core';

@Component({
    selector: 'avl-no-apps',
    templateUrl: './no-apps.component.html',
    styleUrls: ['./no-apps.component.scss'],
})
export class NoAppsComponent {

}
