import { createAction, props } from '@datorama/akita-ng-effects';
import { ITitleInfo } from '../../types';
import { RegistrySearchType } from '@constants';

const ACTION_NAME_TAG = '[Land Registry]';

export const changeSearchType = createAction(
    `${ACTION_NAME_TAG} Change search type`,
    props<{ searchType: RegistrySearchType }>(),
);

export const addTitleToBasket = createAction(
    `${ACTION_NAME_TAG} Add title to the basket`,
    props<{ title: ITitleInfo }>(),
);

export const removeTitleFromBasket = createAction(
    `${ACTION_NAME_TAG} Remove title from the basket`,
    props<{ titleNumber: string }>(),
);

export const updateBasketList = createAction(
    `${ACTION_NAME_TAG} Update the basket list`,
    props<{ titles: ITitleInfo[] }>(),
);

export const doPurchaseTitles = createAction(
    `${ACTION_NAME_TAG} Do purchase titles`,
    props<{ folderId: string }>(),
);

export const titlesPurchaseSuccessful = createAction(
    `${ACTION_NAME_TAG} Title purchase successful`,
    props<{ message: string }>(),
);

export const titlesPurchaseFailed = createAction(
    `${ACTION_NAME_TAG} Title purchase failed`,
    props<{ message: string }>(),
);
