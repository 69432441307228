import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LandRegistrySearchSource } from '../enums/land-registry-search-source.enum';
import { PurchasedTitleDetails } from '../types/purchased-title-details.type';
import { IFilesForDownload, IPurchasedFile, ITileNumberForPurchase, ITitleInfo, mapTitleInfo, TitleInfoToMap } from '../types';

@Injectable()
export class LandRegistryApi {

    constructor(
        private readonly http: HttpClient,
    ) {
    }

    public getPurchasedFilesList(params: HttpParams): Observable<HttpResponse<IPurchasedFile[]>> {
        return this.http.get<IPurchasedFile[]>('/api/hmlr/purchased', { params, observe: 'response' });
    }

    public downloadPurchasedFiles(files: IFilesForDownload[]): Observable<HttpResponse<any>> {
        return this.http.post('/api/hmlr/purchased', files,
            { responseType: 'arraybuffer', observe: 'response' },
        );
    }

    public search(folderId: string, kind: string, query: string, source: LandRegistrySearchSource = LandRegistrySearchSource.default): Observable<HttpResponse<any>> {
        return this.http.post(`/api/hmlr/search/${folderId}`, { kind, query, source }, { observe: 'response' });
    }

    public refresh(folderId: string): Observable<HttpResponse<any>> {
        return this.http.post(`/api/hmlr/refresh/${folderId}`, null, { observe: 'response' });
    }

    public getSearchStatus(url: string): Observable<HttpResponse<ITitleInfo[]>> {
        return this.http.get<TitleInfoToMap[]>(url, { observe: 'response' })
            .pipe(
                map((response) =>
                    new HttpResponse({
                        ...response,
                        body: (response.body || []).map((title) => mapTitleInfo(title)),
                    }),
                ),
            );
    }

    public purchaseTitles(folderId: string, titleNumbers: ITileNumberForPurchase[]): Observable<HttpResponse<any>> {
        return this.http.post(`/api/hmlr/purchase/${folderId}`, titleNumbers, { observe: 'response' });
    }

    public getPurchaseStatus(url: string): Observable<HttpResponse<PurchasedTitleDetails[]>> {
        return this.http.get<PurchasedTitleDetails[] | ''>(url, { observe: 'response' })
            .pipe(
                map((response) =>
                    new HttpResponse({
                        ...response,
                        body: response.body || [],
                    }),
                ),
            );
    }

    public downloadSearchResults(url: string): Observable<HttpResponse<any>> {
        return this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
    }
}
