<mat-sidenav-container
    class="sidenav"
>
    <mat-sidenav
        mode="over"
        #sidenav
    >
        <avl-sidenav (createNewProject)="sidenav.close(); createNewProject()"></avl-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="notice-container">
            <avl-header
                [logo]="logo"
                [showHamburgerMenu]="true"
                [homePagePath]="routingNotice"
                [folderIsNotCreated]="folderIsNotCreated$ | async"
                [folderDetails]="folderDetails$ | async"
                (projectDetailsOpened)="onFolderDetailsOpen()"
                (newFolderCreated)="createNewProject();"
                (sideNavToggled)="sidenav.toggle()"
            ></avl-header>
            <avl-notice-documents></avl-notice-documents>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
