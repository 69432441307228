import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { landRegistrySearchHints, landRegistrySearchTypes, MapSearchType, mapSearchTypeName, RegistrySearchType } from 'app/core/constants';
import { specialTitles } from 'app/onboarding/constants/special-title.constants';
import { BehaviorSubject, interval, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, filter, finalize, map, reduce, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { OnboardingManageService } from 'app/onboarding/services';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MapSearchApi } from '../../api';
import { ThemesService } from '../../services';
import { environment } from '@env/environment';
import { regexes } from '@constants';
import { mapAddressesSearchResultToSuggestions, mapTitleNumbersSearchResultToSuggestions, Suggestion, SuggestionsGroup } from './types/suggestion.type';
import { SearchResult } from './types/search-result.type';

type SuggestionsGroups = {
    titleNumber: SuggestionsGroup;
    address: SuggestionsGroup;
};

type SuggestionsGroupsPartial = {
    titleNumber: Partial<SuggestionsGroup>;
    address: Partial<SuggestionsGroup>;
};

@Component({
    selector: 'avl-land-registry-search',
    templateUrl: './land-registry-search.component.html',
    styleUrls: ['./land-registry-search.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LandRegistrySearchComponent implements OnInit, OnDestroy {
    public readonly minCharsToSuggestions = 3;
    public readonly searchTypes = landRegistrySearchTypes;
    public readonly searchHints = landRegistrySearchHints;

    @Input()
    public isLoading: boolean;

    @Input()
    public searchType = RegistrySearchType.titleNumber;

    @Input()
    public typeChanged: Observable<string>;

    @Output()
    public searchSubmitted = new EventEmitter<SearchResult>();

    @Output()
    public searchTypeChanged = new EventEmitter<RegistrySearchType>();

    public searchForm: FormGroup;
    public isFormDisabled = false;
    public suggestions$: BehaviorSubject<SuggestionsGroup[]>;
    public suggestionGroups$: BehaviorSubject<SuggestionsGroups>;

    @ViewChild('searchButton', { static: true })
    private readonly searchButton: ElementRef;

    @ViewChild(MatAutocompleteTrigger)
    private readonly autocomplete: MatAutocompleteTrigger;

    private readonly destroy$ = new Subject<void>();
    private isQueryCompleted = false;
    private titleNumberSuggestionsSubscription: Subscription = null;
    private addressSuggestionsSubscription: Subscription = null;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly onboarding: OnboardingManageService,
        private readonly mapSearchApi: MapSearchApi,
        private readonly themesService: ThemesService,
    ) {
        this.suggestions$ = new BehaviorSubject([]);
        this.suggestionGroups$ = new BehaviorSubject({
            titleNumber: {
                type: MapSearchType.titleNumber,
                typeName: mapSearchTypeName.get(MapSearchType.titleNumber),
                suggestions: [],
                isLoading: false,
            },
            address: {
                type: MapSearchType.address,
                typeName: mapSearchTypeName.get(MapSearchType.address),
                suggestions: [],
                isLoading: false,
            },
        });
        const isOldTheme = this.themesService.getIsOldTheme();

        this.searchTypes = this.searchTypes.filter((el) => {
            const isMapSearchTypeHidden = el.value === RegistrySearchType.map && isOldTheme;
            const isWhat3WordsSearchTypeHidden = el.value === RegistrySearchType.what3Words && !environment.isWhat3WordsSearchEnabled;

            return !isMapSearchTypeHidden && !isWhat3WordsSearchTypeHidden;
        });
    }

    public ngOnInit(): void {
        this.initForm();
        this.tryToStartOnboardingFlow(this.searchType);
        this.initSuggestions();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public isMapSearchType(): boolean {
        return this.searchForm.get('kind').value === RegistrySearchType.map;
    }

    public onKindSelectionChange(): void {
        const selectedType = this.searchForm.get('kind').value;

        this.searchTypeChanged.emit(selectedType);
        this.searchForm.get('query').reset();
        this.tryToStartOnboardingFlow(selectedType);
    }

    public submit(): void {
        const searchOptions: SearchResult = this.searchForm.value;

        if (this.isMapSearchType()) {
            const query = searchOptions.query;
            const suggestionGroup = this.findSuggestionsGroup(query);
            const groupType = suggestionGroup.type;

            searchOptions.locationType = groupType;

            if (groupType === MapSearchType.address) {
                searchOptions.placeId = this.findSuggestion(query).placeId;
            } else if (groupType === MapSearchType.titleNumber) {
                searchOptions.location = this.findSuggestion(query)?.location;
            }
        }

        this.searchSubmitted.emit(searchOptions);
        this.autocomplete.closePanel();
    }

    public isSubmitEnabled(): boolean {
        if (this.isLoading) {
            return false;
        }

        if (this.isMapSearchType()) {
            if (this.isSuggestionsLoading()) {
                return false;
            }

            return this.isQueryCompleted;
        }

        return !!this.searchForm.value.query;
    }

    public isQueryMatchMinLength(query: string): boolean {
        return !!query && query.length >= this.minCharsToSuggestions;
    }

    public suggestionSelected(event: MatAutocompleteSelectedEvent): void {
        if (!event.option.value) {
            return;
        }

        this.submit();
    }

    public isSuggestionsLoading(): boolean {
        const isTitleNumberLoading = !!this.suggestionGroups$.getValue().titleNumber.isLoading;
        const isAddressLoading = !!this.suggestionGroups$.getValue().address.isLoading;

        return isTitleNumberLoading || isAddressLoading;
    }

    public isSuggestionExists(): boolean {
        const isTitleNumberSuggestionExists = !!this.suggestionGroups$.getValue().titleNumber.suggestions.length;
        const isAddressSuggestionExists = !!this.suggestionGroups$.getValue().address.suggestions.length;

        return isTitleNumberSuggestionExists || isAddressSuggestionExists;
    }

    public isSuggestionVisible(): boolean {
        const query = this.query();
        const isSuggestionExists = this.isSuggestionExists();

        return isSuggestionExists || query.length < 3 && !isSuggestionExists;
    }

    private tryToStartOnboardingFlow(currentSearchType: RegistrySearchType): void {
        if (currentSearchType === RegistrySearchType.titleNumber) {
            this.onboardSearchField();
        }
    }

    private onboardSearchField(): void {
        this.onboarding.showLandRegistryStep()
            .pipe(
                switchMap(() => this.onboarding.showSearchFieldStep()),
                takeUntil(this.destroy$),
                tap(() => this.isFormDisabled = true),
                delay(300),
                switchMap(() => this.startOnboardingSearch()),
            )
            .subscribe(() => {
                this.isFormDisabled = false;
            });
    }

    private startOnboardingSearch(): Observable<string> {
        return interval(300)
            .pipe(
                take(specialTitles.length),
                map((index) => specialTitles[index]),
                reduce((accumulator, current) => {
                    accumulator += current;
                    this.searchForm.get('query').setValue(accumulator);
                    return accumulator;
                }),
                delay(300),
                tap(() => {
                    if (this.onboarding.isActive) {
                        this.searchButton.nativeElement.click();
                        this.onboarding.showPurchaseTraining();
                    }
                }),
            );
    }

    private initForm(): void {
        this.searchForm = this.formBuilder.group({
            kind: [this.searchType],
            query: [''],
        });
    }

    private initSuggestions(): void {
        this.suggestionGroups$.subscribe((suggestions) => {
            const groupsList: SuggestionsGroup[] = [...this.suggestions$.getValue()];

            if (suggestions.titleNumber.suggestions.length && !this.suggestions$.getValue().find((group) => group.type === MapSearchType.titleNumber)) {
                groupsList.push(suggestions.titleNumber);
            }

            if (suggestions.address.suggestions.length && !this.suggestions$.getValue().find((group) => group.type === MapSearchType.address)) {
                groupsList.push(suggestions.address);
            }

            this.suggestions$.next(groupsList);

            const searchQuery = this.query();
            this.isQueryCompleted = this.hasCoincidenceInSuggestions(searchQuery);
        });

        this.searchForm.get('query').valueChanges
            .pipe(
                tap((query) => {
                    if (!this.isQueryMatchMinLength(query)) {
                        this.stopSuggestionsRequests();
                        this.suggestions$.next([]);
                        this.updateSuggestionGroups({
                            titleNumber: { suggestions: [] },
                            address: { suggestions: [] },
                        });
                    }

                    if (this.isSuggestionExists() || this.isQueryMatchMinLength(query)) {
                        this.updateSuggestionGroups({
                            titleNumber: { isLoading: true },
                            address: { isLoading: true },
                        });
                    }
                }),
                debounceTime(1000),
                filter((query) => {
                    return this.isMapSearchType() && this.isQueryMatchMinLength(query);
                }),
            )
            .subscribe((query) => {
                this.requestSuggestions(query);
            });
    }

    private requestSuggestions(query: string): void {
        this.stopSuggestionsRequests();
        this.suggestions$.next([]);
        this.updateSuggestionGroups({
            titleNumber: { suggestions: [] },
            address: { suggestions: [] },
        });

        if (this.isQueryMatchTitleNumberRegex(query)) {
            this.requestTitleNumberSuggestions(query);
        } else {
            this.updateSuggestionGroups({ titleNumber: { isLoading: false } });
        }

        this.requestAddressSuggestions(query);
    }

    private requestTitleNumberSuggestions(query: string): void {
        this.titleNumberSuggestionsSubscription = this.mapSearchApi
            .titleNumberSuggestions(query, { isCacheEnabled: true })
            .pipe(
                finalize(() => this.updateSuggestionGroups({ titleNumber: { isLoading: false } })),
            )
            .subscribe((searchResult) => {
                const suggestions = mapTitleNumbersSearchResultToSuggestions(searchResult);
                this.updateSuggestionGroups({ titleNumber: { suggestions } });
            });
    }

    private requestAddressSuggestions(query: string): void {
        this.addressSuggestionsSubscription = this.mapSearchApi
            .addressSuggestions(query, { isCacheEnabled: true })
            .pipe(
                finalize(() => this.updateSuggestionGroups({ address: { isLoading: false } })),
            )
            .subscribe((searchResult) => {
                const suggestions = mapAddressesSearchResultToSuggestions(searchResult);
                this.updateSuggestionGroups({ address: { suggestions } });
            });
    }

    private isQueryMatchTitleNumberRegex(query: string): boolean {
        const preparedQuery = query.toUpperCase().trim();

        return regexes.titleNumber.test(preparedQuery);
    }

    private updateSuggestionGroups(groups: Partial<SuggestionsGroupsPartial>): void {
        const updatedGroups: SuggestionsGroups = { ...this.suggestionGroups$.getValue() };

        if (groups.titleNumber) {
            updatedGroups.titleNumber = { ...updatedGroups.titleNumber, ...groups.titleNumber };
        }

        if (groups.address) {
            updatedGroups.address = { ...updatedGroups.address, ...groups.address };
        }

        this.suggestionGroups$.next(updatedGroups);
    }

    private hasCoincidenceInSuggestions(query: string): boolean {
        return !!this.findSuggestion(query);
    }

    private findSuggestion(query: string): Suggestion | undefined {
        if (!query) {
            return undefined;
        }

        const lowerCaseQuery = query.toLowerCase();
        const loadedSuggestions = this.combineAllSuggestionsGroups(this.suggestionGroups$.value);

        for (const group of loadedSuggestions) {
            const value = group.suggestions.find((option) => option.value.toLowerCase() === lowerCaseQuery);
            if (value) {
                return value;
            }
        }
    }

    private findSuggestionsGroup(query: string): SuggestionsGroup | undefined {
        if (!query) {
            return undefined;
        }

        const lowerCaseQuery = query.toLowerCase();
        const loadedSuggestions = this.combineAllSuggestionsGroups(this.suggestionGroups$.value);

        for (const group of loadedSuggestions) {
            const value = group.suggestions.find((option) => option.value.toLowerCase() === lowerCaseQuery);
            if (value) {
                return group;
            }
        }
    }

    private combineAllSuggestionsGroups(suggestionsGroups: SuggestionsGroups): SuggestionsGroup[] {
        const groupsList: SuggestionsGroup[] = [];

        if (suggestionsGroups.titleNumber) {
            groupsList.push(suggestionsGroups.titleNumber);
        }

        if (suggestionsGroups.address) {
            groupsList.push(suggestionsGroups.address);
        }

        return groupsList;
    }

    private query(): string {
        return this.searchForm.get('query').value?.trim() || '';
    }

    private stopSuggestionsRequests(): void {
        this.titleNumberSuggestionsSubscription?.unsubscribe();
        this.addressSuggestionsSubscription?.unsubscribe();
    }
}
