import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';
import { TitlesResolve } from './resolvers/titles.resolve';
import { OnboardingModule } from 'app/onboarding/onboarding.module';

import { BookmarksApi, DocumentApi, FolderApi, GeoSearchApi, LandRegistryApi, MapSearchApi, ReportApi, SchedulesApi } from './api';

import {
    DataService,
    DocumentsService,
    FeatureUtilsService,
    FolderService,
    GeneratedReportsService,
    ImanageService,
    LandRegistrySearchService,
    MapService,
    NotifyCountersService,
    PurchaseService,
    RefreshService,
    ReportGenerationHandlerService,
    ReportService,
    SchedulesService,
    ThemesService,
} from './services';

import {
    DocumentsQuery,
    DocumentsStore,
    FailedDocumentsQuery,
    FailedDocumentsStore,
    FailedPurchasesQuery,
    FailedPurchasesStore,
    FolderQuery,
    FolderStore,
    GeneratedReportsQuery,
    GeneratedReportsStore,
    MapSearchService,
    NotifyCountersQuery,
    NotifyCountersStore,
    PurchasedFilesQuery,
    PurchasedFilesStore,
    PurchasedSelectedFilesQuery,
    PurchasedSelectedFilesStore,
    ReportOptionsQuery,
    ReportOptionsStore,
    SchedulesQuery,
    SchedulesStore,
    SearchResultsQuery,
    SearchResultsStore,
} from './store';

import { TitleRegistersComponent } from './components/title-registers/title-registers.component';
import { PurchasedPageComponent } from './components/purchased-page/purchased-page.component';
import { ReportsPageComponent } from './components/reports-page/reports-page.component';
import { TitlesUploadComponent } from './components/titles-upload/titles-upload.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { DropAreaComponent } from './components/drop-area/drop-area.component';

import { LandRegistryPreOptionsDialogComponent } from './components/dialogs/land-registry-pre-options-dialog/land-registry-pre-options-dialog.component';
import { LandRegistryDialogComponent } from './components/dialogs/land-registry-dialog/land-registry-dialog.component';
import { ReportGenerationDialogComponent } from './components/dialogs/report-generation-dialog/report-generation-dialog.component';
import { ReportsTableComponent } from './components/reports-table/reports-table.component';
import { PurchasedFilesTableComponent } from './components/purchased-files-table/purchased-files-table.component';
import { PurchaseDownloadButtonComponent } from './components/buttons/purchase-download-button/purchase-download-button.component';
import { SelectionToolbarComponent } from './components/selection-toolbar/selection-toolbar.component';
import { DownloadReportDialogComponent } from './components/dialogs/download-report-dialog/download-report-dialog.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { LandRegistrySearchComponent } from './components/land-registry-search/land-registry-search.component';
import { LandRegistryResultsTableComponent } from './components/land-registry-results-table/land-registry-results-table.component';
import { PurchaseTitlesButtonComponent } from './components/buttons/purchase-titles-button/purchase-titles-button.component';
import { ProjectDetailsDialogComponent } from './components/dialogs/project-details-dialog/project-details-dialog.component';
import { DownloadReportButtonComponent } from './components/buttons/download-report-button/download-report-button.component';
import { SchedulePurchasesButtonComponent } from './components/buttons/schedule-purchases-button/schedule-purchases-button.component';
import { TitlesSchedulesTableComponent } from './components/titles-schedules/titles-schedules.component';
import { SchedulesTableComponent } from './components/schedules-table/schedules-table.component';
import { SchedulesResolve } from './resolvers/schedules.resolve';
import { DownloadSchedulePreviewDialogComponent } from './components/dialogs/download-schedule-preview-dialog/download-schedule-preview-dialog.component';
import { FiledCopiesDialogComponent } from './components/dialogs/filed-copies-dialog/filed-copies-dialog.component';
import { ImanageAuthComponent } from './components/imanage-auth/imanage-auth.component';
import { ShowImanageDialogComponent } from './components/dialogs/show-imanage-dialog/show-imanage-dialog.component';
import { LandRegistryMapSearchModule } from './components/land-registry-map-search/map-search.module';
import { LandRegistryEffects, LandRegistryQuery, LandRegistryService, LandRegistryStore } from './store/land-registry';
import { AkitaNgEffectsModule } from '@datorama/akita-ng-effects';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ThemesResolve } from './resolvers/themes.resolve';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [
        TitleRegistersComponent,
        DocumentsComponent,
        DropAreaComponent,
        ReportGenerationDialogComponent,
        DownloadReportDialogComponent,
        TitlesUploadComponent,
        SidenavComponent,
        PurchasedPageComponent,
        ReportsPageComponent,
        PurchasedFilesTableComponent,
        PurchaseDownloadButtonComponent,
        SelectionToolbarComponent,
        ReportsTableComponent,
        LandRegistryDialogComponent,
        LandRegistryPreOptionsDialogComponent,
        LandRegistrySearchComponent,
        LandRegistryResultsTableComponent,
        PurchaseTitlesButtonComponent,
        ProjectDetailsDialogComponent,
        DownloadReportButtonComponent,
        SchedulePurchasesButtonComponent,
        TitlesSchedulesTableComponent,
        SchedulesTableComponent,
        DownloadSchedulePreviewDialogComponent,
        FiledCopiesDialogComponent,
        ImanageAuthComponent,
        ShowImanageDialogComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        OnboardingModule,
        LandRegistryMapSearchModule,
        AkitaNgEffectsModule.forFeature([
            LandRegistryEffects,
        ]),
        MatAutocompleteModule,
        DragDropModule,
    ],
    providers: [
        GeoSearchApi,
        MapSearchApi,
        MapSearchService,
        TitlesResolve,
        ThemesResolve,
        ThemesService,
        DocumentApi,
        FolderApi,
        ReportApi,
        LandRegistryApi,
        BookmarksApi,
        DocumentsService,
        PurchaseService,
        ReportService,
        DataService,
        ImanageService,
        FeatureUtilsService,
        FolderService,
        GeneratedReportsService,
        LandRegistrySearchService,
        ThemesService,
        MapService,
        NotifyCountersService,
        RefreshService,
        ReportGenerationHandlerService,
        DocumentsStore,
        DocumentsQuery,
        FolderStore,
        FolderQuery,
        FailedDocumentsStore,
        FailedDocumentsQuery,
        FailedPurchasesStore,
        FailedPurchasesQuery,
        ReportOptionsStore,
        ReportOptionsQuery,
        PurchasedFilesQuery,
        PurchasedFilesStore,
        PurchasedSelectedFilesStore,
        PurchasedSelectedFilesQuery,
        GeneratedReportsQuery,
        GeneratedReportsStore,
        SearchResultsStore,
        SearchResultsQuery,
        NotifyCountersStore,
        NotifyCountersQuery,
        SchedulesService,
        SchedulesApi,
        SchedulesStore,
        SchedulesQuery,
        SchedulesResolve,
        LandRegistryStore,
        LandRegistryQuery,
        LandRegistryService,
    ],
    exports: [
        SidenavComponent,
    ],
})
export class TitlesModule {
}
