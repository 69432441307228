import { ReportAction } from '@core/types';

export type ReportActionInOrder = Pick<ReportAction, 'key' | 'format' | 'displayOrder'>;

export const orderedReportActions: ReportActionInOrder[] = [
    {
        displayOrder: 0,
        key: 'key-issues',
        format: 'docx',
    },
    {
        displayOrder: 1,
        key: 'scoping',
        format: 'docx',
    },
    {
        displayOrder: 3,
        key: 'full',
        format: 'xlsx',
    },
    {
        displayOrder: 5,
        key: 'summary',
        format: 'docx',
    },
    {
        displayOrder: 7,
        key: 'full',
        format: 'zip',
    },
    {
        displayOrder: 9,
        key: 'full',
        format: 'docx',
    },
    {
        displayOrder: 11,
        key: 'scottish-key-issues',
        format: 'docx',
    },
    {
        displayOrder: 13,
        key: 'scottish-full',
        format: 'xlsx',
    },
    {
        displayOrder: 15,
        key: 'scottish-summary',
        format: 'docx',
    },
    {
        displayOrder: 17,
        key: 'scottish-full',
        format: 'zip',
    },
    {
        displayOrder: 19,
        key: 'scottish-full',
        format: 'docx',
    },
    {
        displayOrder: 21,
        key: 'scottish-title-sheets',
        format: 'zip',
    },
    {
        displayOrder: 23,
        key: 'cot',
        format: 'zip',
    },
    {
        displayOrder: 25,
        key: 'cot-combined',
        format: 'docx',
    },
    {
        displayOrder: 27,
        key: 'official-copies',
        format: 'zip',
    },
    {
        displayOrder: 29,
        key: 'scottish-cot',
        format: 'zip',
    },
    {
        displayOrder: 31,
        key: 'scottish-cot-long',
        format: 'zip',
    },
    {
        displayOrder: 32,
        key: 'nhf-cot',
        format: 'docx',
    },
];
